.strive-logo {
	position: relative;
	overflow: visible;
	color: @brand-dark;
	.icon-strive-logo-icon();
	.icon-strive-logo-text-after();
	&:before {
		display: inline-block;
		margin: 0 .5em 0 .4em;
		transform: scale(1.7);
	}
	span {
		&:extend(.text-hide);
	}
}

.strive-label {
	position: relative;
	display: inline-block;
	font-size: 9px;
	color: @white;
	background-color: @brand-primary;
	border-radius: 1em;
	padding: .12em .56em;
	text-transform: uppercase;
	letter-spacing: .5px;
}
