.wrapping-flexbox() {
	display: flex;
	flex-wrap: wrap;
}

.mobile-column-flexbox() {
	@media (max-width: @screen-sm-min) {
		flex: 0 0 100%;
	}
}

.layout-gutters {
	> * {
		padding-right: 2rem;

		&:last-child {
			padding-right: 0;
		}
	}
}

.layout-two-columns {
	.wrapping-flexbox();

	> * {
		flex: 0 0 50%;
		.mobile-column-flexbox();
	}

	&.layout-elastic {
		> * {
			flex: 1;
			min-width: 50%;
			max-width: 100%;
		}
	}
}

.layout-three-columns {
	.wrapping-flexbox();

	> * {
		flex: 0 0 33.3%;
		.mobile-column-flexbox();
	}

	&.layout-elastic {
		> * {
			flex: 1;
			min-width: 33.3%;
			max-width: 100%;
		}
	}
}

.layout-four-columns {
	.wrapping-flexbox();

	> * {
		flex: 0 0 25%;
		.mobile-column-flexbox();
	}
}

.layout-five-columns {
	.wrapping-flexbox();

	> * {
		flex: 0 0 20%;
		.mobile-column-flexbox();
	}
}


