.filter-list {
	.expandable, &.expandable {
		.expander {
			.icon-chevron-right();
			&:before {
				display: inline-block;
				transition: all 0.2s ease-in-out;
				color: @text-muted;
				font-size: .8em;
				margin-left: -1.1em;
			}
		}
		&.expanded {
			.expander:before {
				transform: rotate(90deg);
			}
		}
	}

	.parented {
		> label,
		.filter-menu .dropdown-toggle strong {
			font-weight: normal;
		}
		.slide-in(@max-height: 36px, @time: .2s);
	}
	&.actions-group-filters .parented {
		.slide-in(@max-height: 20px, @time: .2s);
	}
}
