.app-table {
	&:extend(.table all);
	&:extend(.table-hover all);

	&:not(.no-animate) tr {
		transition: all .2s ease-in-out;
		.ng-repeat-changes();
	}

	thead {
		background-color: @body-bg;

		&.sticky-header {
			transition: box-shadow .2s ease-in-out;
		}

		&.sticky-header-active {
			background-color: fade(@body-bg, 95%);
			box-shadow: 0 3px 3px -3px rgba(0, 0, 0, .1);
		}
	}

	td:not(.options) > a {
		color: @text-color;
		display: block;
		margin: -@table-cell-padding;
		padding: @table-cell-padding;
		&:hover {
			color: @link-hover-color;
			text-decoration: none;
		}
	}

	.small-header() {
		font-size: .8em;
	}

	.number() {
		text-align: right;
	}
	.big-number() {
		font-size: 1.4em;
		min-width: 3em;
	}

	> tbody > tr > td {
		vertical-align: middle;
		color: @text-muted;
	}
	tbody .status, thead .status {
		color: @text-color;
		padding-right: 0;
		font-size: 1.2rem;
		> a {
			padding-right: 0;
			margin-right: 0;
		}
	}
	thead.sticky-header .status {
		transition: border-bottom-color .3s ease-in-out;
	}
	tbody .status, thead:not(.sticky-header-active) .status {
		border-top-color: transparent;
		border-bottom-color: transparent;
	}

	.inline-dropdown(@classname) {
		.@{classname} {
			white-space: nowrap;
			.dropdown-toggle {
				color: @text-muted;
				cursor: pointer;
				display: block;
				border: 1px solid transparent;
				border-radius: @border-radius-base;
				padding: @padding-base-vertical @padding-base-horizontal + 14px @padding-base-vertical @padding-base-horizontal;
				&:hover, &:focus {
					text-decoration: none;
				}
				.caret {
					visibility: hidden;
					position: absolute;
					right: @padding-base-horizontal;
					top: 50%;
					transform: translateY(-50%);
				}
			}
		}
		tr:hover .@{classname} .dropdown-toggle, .@{classname} .dropdown-toggle:focus {
			border-color: @text-muted;
			color: @link-color;
			.caret {
				visibility: visible;
			}
		}
	}

	// lighter styling on inline-selects
	tr .form-control-select {
		transition: none;
	}
	tr:not(:hover) .form-control-select:not(.focused) {
		border-color: transparent;
		background: none;
		box-shadow: none;
		select {
			box-shadow: none;
		}
		&:after {
			opacity: .5;
			border-color: transparent;
			background: none;
		}
	}

	.options {
		opacity: 0;
		transition: .2s opacity ease-in-out;

		a:before {
			padding-right: 4px;
		}

		@media (max-width: @screen-xs-max) {
			display: none;
		}
	}
	tr:hover {
		.options {
			opacity: 1;
		}
	}

	tr.moved {
		opacity: 0.5;
		&:hover {
			opacity: .8;
		}
	}

	td .inline-edit-wrapper .inline-edit-widget > * {
		margin: -(@padding-base-vertical + 1px) -(@padding-small-horizontal + 1px);
	}
}

