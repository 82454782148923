.app-content, .modal-content {
	h2 {
		font-size: 2.2rem;
		.circle-icon();
		&.user {
			.icon-user();
		}

		&.edit {
			.icon-pencil();
		}

		&.assessment {
			.icon-job();
		}

		&.challenge {
			.icon-rubiks();
		}
		&.add-challenge {
			.icon-rubiks-add();
		}

		&.notifications {
			.icon-broadcast();
		}
		&.team {
			.icon-office();
		}
		&.team-members {
			.icon-users();
		}
	}

	> div > h2 {
		&:first-child {
			margin-top: 0;
		}
	}

	.bar {
		margin-bottom: 1rem;
	}

	.header-bar {
		margin-bottom: 2rem;
	}

	.label-dark {
		background-color: @brand-dark;
	}

	.label-default {
		background: @brand-light-dark;
		color: @brand-dark;
	}
}

