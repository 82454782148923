.legal-links {
	padding: .5rem 1rem;
	font-size: 1.2rem;
	text-align: center;
	ul, li {
		list-style: none;
		padding: 0;
		margin: 0;
	}

	li {
		display: inline-block;
	}

	li + li {
		margin-left: 2rem;
	}
}
