.navbar {
	.dark-bg();
	border: none;
	.navbar-brand a {
		color: @navbar-default-link-color;
		&:hover {
			color: @navbar-default-link-hover-color;
		}
	}
}


.navbar.navbar-fixed-top {
	// make room for intercom button
	padding-right: @navbar-height;
}

@navbar-active-border-height: 4px;
.navbar-default .navbar-nav > li > a {
	.no-focus-border();
	transition: all .25s ease-in-out;
	border-bottom: @navbar-active-border-height solid fade(@navbar-default-link-active-border, 0%);
	padding-bottom: @navbar-padding-vertical - @navbar-active-border-height;
}

.navbar-default .navbar-nav > .active {
	> a, > a:focus, > a:active {
		border-bottom-color: @navbar-default-link-active-border;
	}
}

.navbar-light {
	.navbar-text {
		color: @text-color;
	}
	.navbar-nav > li > a {
		color: @text-color;
		&:hover, &:focus {
			background-color: @darken;
			color: @link-color;
		}
	}
}

.subnav {
	z-index: @zindex-navbar-fixed - 1;
	position: fixed;
	top: @navbar-height;
	width: 100%;
	overflow: hidden;
	border: none;
	border-radius: 0;
	height: 0;
	min-height: 0;
	transition: .2s all ease-in-out;
	.has-subnav & {
		height: @subnavbar-height;
		min-height: @subnavbar-height;
	}

	&:extend(.navbar all);
	background-color: @subnavbar-bg;
	color: @subnavbar-color;
	margin-bottom: 0;

	.breadcrumb {
		padding: 0;
		margin: 0;
		background: transparent;
		font-size: 12px;
		letter-spacing: 1px;
		a {
			color: @gray;
			margin-right: 5px;
		}
	}

	.btn-sm {
		margin-top: 2px;
	}

	form.navbar-right {
		margin-right: -2.5rem;
	}
}

.has-subnav .navbar {
	margin-bottom: 0;
}

.nav-tabs {
	> li {
		.ng-repeat-changes();
	}
	> li > a {
		.no-focus-border();

		color: @brand-dark;

		&:hover, &:focus {
			background-color: @border-color;
		}
	}

	> li > .text {
		&:extend(.nav > li > a);
		&:extend(.nav-tabs > li > a);
	}

	> li > a span:before {
		margin-right: .5rem;
	}

	> li.control-tab {
		padding: @nav-link-padding;
		padding-top: 4px;
		padding-bottom: 0;
	}

}
