.side-lined-text(@bg-color: @body-bg, @line-color: @border-color) {
	text-align: center;
	border-bottom: 1px solid @line-color;
	line-height: 0.1em;
	margin: 1.5em 0;
	span {
		background-color: @bg-color;
		padding: 0 .5em;
		font-size: 1.8rem;
	}
}

.circle-icon {
	&:before {
		padding: 4px;
		background-color: darken(@gray, 5%);
		color: @white;
		margin-right: 1rem;
		border-radius: 50%;
	}
}

.no-icon {
	&:before, &:after {
		content: none !important;
		margin: 0 !important;
		padding: 0 !important;
	}
}

.text-ellipsis {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.vertical-align {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

.center {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.link {
	color: @link-color;
	cursor: pointer;
	&:hover {
		color: @link-hover-color;
	}
}

.no-focus-border {
	&:focus {
		outline: 0 !important;
	}
}

.no-active-border {
	&:active {
		outline: 0 !important;
	}
}

.lightened-bg-hover(@bg-color) {
	background-color: @bg-color;
	&:hover {
		background-color: lighten(@bg-color, 10%);
	}
}

.darkened-bg-hover(@bg-color) {
	background-color: @bg-color;
	&:hover {
		background-color: darken(@bg-color, 5%);
	}
}

.overflow-auto() {
	overflow: auto;
	-webkit-overflow-scrolling: touch;
}

/* usage: .radial-gradient(50%, 50%, circle, cover, #ffffff, 0%, #000000, 100%); */
.radial-gradient(@centerX: center, @centerY: center, @shape: circle, @size: contain, @startColor: #555, @startPos: 0, @endColor: #333, @endPos: 100%) {
	background-color: @endColor;
	background-image: -moz-radial-gradient(@centerX @centerY, @shape @size, @startColor @startPos, @endColor @endPos);
	background-image: -webkit-gradient(radial, @centerX @centerY, @shape @size, @startColor @startPos, @endColor @endPos);
	background-image: -webkit-radial-gradient(@centerX @centerY, @shape @size, @startColor @startPos, @endColor @endPos);
	background-image: -o-radial-gradient(@centerX @centerY, @shape @size, @startColor @startPos, @endColor @endPos);
	background-image: -ms-radial-gradient(@centerX @centerY, @shape @size, @startColor @startPos, @endColor @endPos);
	background-image: radial-gradient(@shape at @centerX @centerY, @startColor @startPos, @endColor @endPos);
}


/*
Makes it easy to create images that load higher resolution based on media queries.
Assumes you'll have 6 images, 3 at the @xs, @sm, and @md, and 3 at double those sizes.
Also assumes that @xs * 2 > @md.
*/
.media-background(@base, @ext: '.jpg', @xs: 600, @sm: 800, @md: 1000) {
	.bg-img(@size) { background-image: url("@{base}-@{size}@{ext}"); }

	@xs2x: @xs * 2;
	@sm2x: @sm * 2;
	@md2x: @md * 2;
	@lg: @xs2x;
	@xl: @sm2x;
	@xxl: @md2x;
	@sm-min: unit((@xs + 1), px);
	@md-min: unit((@sm + 1), px);
	@lg-min: unit((@md + 1), px);
	@xl-min: unit((@lg + 1), px);
	@xxl-min: unit((@xl + 1), px);

	.bg-img(@xs);
	@media only screen and (min-device-pixel-ratio: 1.5) {
		.bg-img(@xs2x);
	}
	@media only screen and (min-width: @sm-min) {
		.bg-img(@sm);
	}
	@media only screen and (min-width: @sm-min) and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-width: @sm-min) and (min-device-pixel-ratio: 1.5) {
		.bg-img(@sm2x);
	}
	@media only screen and (min-width: @md-min) {
		.bg-img(@md);
	}
	@media only screen and (min-width: @lg-min) {
		.bg-img(@lg);
	}
	@media only screen and (min-width: @xl-min) {
		.bg-img(@xl);
	}
	@media only screen and (min-width: @xxl-min) {
		.bg-img(@xxl);
	}
	@media only screen and (min-width: @md-min) and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-width: @md-min) and (min-device-pixel-ratio: 1.5) {
		.bg-img(@md2x);
	}
}
