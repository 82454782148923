/* ************************************** */
/* Mandatory CSS required for ng-sortable */
/* ************************************** */

.as-sortable-item, .as-sortable-placeholder {
	display: block;
}

.as-sortable-item {
	-ms-touch-action: none;
	touch-action: none;
}

.as-sortable-item-handle {
	cursor: move;
}

.as-sortable-placeholder {
}

.as-sortable-drag {
	transform: translate(-4px, -4px);
	box-shadow: 0 3px 8px rgba(0, 0, 0, 0.25);
	background-color: @body-bg;
	position: absolute;
	pointer-events: none;
	user-select: none;
	z-index: 9999;
}

.as-sortable-hidden {
	display: none !important;
}

.as-sortable-un-selectable {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
