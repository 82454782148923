nav {
	&:extend(.dark-bg all);

	position: fixed;
	top: 0;
	width: 100%;
	padding: 0 @navbar-padding-horizontal;
	z-index: @zindex-navbar-fixed;

	@media (max-width: @screen-xs-min) {
		padding: 0 @navbar-padding-horizontal / 2;
	}

	> div {
		height: @landing-navbar-height;
		display: flex;
		justify-content: space-between;
		align-items: center;
		max-width: @section-max-width;
		margin: 0 auto;
		> * {
			flex: 0 0 auto;

		}
	}
	ul, li {
		list-style: none;
		padding: 0;
		margin: 0;
	}

	.logo {
		font-size: 2.2rem;
		a {
			text-decoration: none !important;
		}

		@media (max-width: @screen-xs-min) {
			font-size: 1.6rem;
		}
	}

	.login-links {
		margin: 0;
		padding: 0;
		> li {
			display: inline-block;
		}
		a {
			color: @brand-light-dark;
			display: inline-block;
			padding: .6rem 2.5rem;
			text-transform: uppercase;
			font-size: 1.4rem;
			letter-spacing: 2px;
			border: 2px solid transparent;
			border-radius: 18px;
			font-weight: bold;
			transition: all .2s ease-in-out;
			&:hover, &:active, &:focus {
				color: @white;
				text-decoration: none;
				background: fade(@brand-black, 50%);
			}
			&.signup {
				border-color: @brand-light-dark;
				&:hover {
					box-shadow: inset 0 0 4px fade(@white, 40%), 0 0 4px fade(@white, 40%);
				}
			}

			@media (max-width: @screen-xs-min) {
				letter-spacing: 1px;
				padding: .4rem .8rem;
				font-size: 1.2rem;
				border-radius: 16px;
			}
		}
		.team-signup {
			display: none;
		}
	}
}
