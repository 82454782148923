@import "bootstrap/less/variables.less";

//
// Variables
// --------------------------------------------------


//== Colors
//
//## Gray and brand colors for use across Bootstrap.

@gray-base:              #000;
@gray-darker:            #222;
@gray-dark:              #333;
@gray:                   #555;
@gray-light:             #888;
@gray-lighter:           #CCC;
@gray-lightest:			 #DDD;
@off-white:				 #EEE;
@white:					 #FFF;

@brand-primary: #7579F9;
@brand-primary-dark: #4E5693;
@brand-primary-light: #9897f9;
@brand-info: #498DB1;
@brand-success: #5AA179;
@brand-success-light: #D8EEDF;
@brand-success-bright: #9BE0B0;
@brand-warning: #EEB854;
@brand-danger: #B63D4C;
@brand-light: #f0f1f5;
@brand-light-med: #eeeff3;
@brand-light-dark: #e7e8ec;
@brand-lighter: lighten(@brand-light, 3%);
@brand-dark: #2d3037;
@brand-black: #212328;
@brand-accent: #AAA5B5;
@brand-gray: #8F8D8D;
@brand-highlight: lighten(@brand-info, 45%);

@dark-accent: rgba(0,0,0,0.1);
@darken: rgba(0,0,0,0.15);
@lighten: rgba(255,255,255,0.1);
@brighten: rgba(255,255,255,0.4);

//== Scaffolding
//
//## Settings for some of the most global styles.

//** Background color for `<body>`.
@body-bg:               #FFF;
//** Global text color on `<body>`.
@text-color:            @brand-dark;
@text-muted:            @brand-gray;

//** Global textual link color.
@link-color:            darken(@brand-primary-dark, 5%);
//** Link hover color set via `darken()` function.

@link-hover-color:      @brand-primary-dark;
//** Link hover decoration.
@link-hover-decoration: underline;


//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

@import (css) url("//fonts.googleapis.com/css?family=Source+Sans+Pro:400,300,600,400italic,600italic");

@font-family-sans-serif:  "Source Sans Pro", Helvetica, Arial, sans-serif;
@font-family-serif:       Georgia, "Times New Roman", Times, serif;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
@font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace;
@font-family-base:        @font-family-sans-serif;

@font-size-base:          14px;
@font-size-large:         ceil((@font-size-base * 1.25)); // ~18px
@font-size-small:         ceil((@font-size-base * 0.85)); // ~12px
@font-size-xsmall:        ceil((@font-size-base * 0.78)); // ~11px

@font-size-h1:            ceil((@font-size-base * 1.71)); // ~24px
@font-size-h2:            ceil((@font-size-base * 1.42)); // ~20px
@font-size-h3:            ceil((@font-size-base * 1.28)); // ~18px
@font-size-h4:            ceil((@font-size-base * 1.14)); // ~16px
@font-size-h5:            @font-size-base;
@font-size-h6:            ceil((@font-size-base * 0.85)); // ~12px

//** Unit-less `line-height` for use in components like buttons.
@line-height-base:        1.428571429; // 20/14
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
@line-height-computed:    floor((@font-size-base * @line-height-base)); // ~20px

//** By default, this inherits from the `<body>`.
@headings-font-family:    inherit;
@headings-font-weight:    500;
@headings-line-height:    1.1;
@headings-color:          @brand-dark;


//== Components
//
//## Define common padding and border radius sizes and more. Values based on 14px text and 1.428 line-height (~20px to start).

@padding-base-vertical:     6px;
@padding-base-horizontal:   12px;

@padding-large-vertical:    10px;
@padding-large-horizontal:  16px;

@padding-small-vertical:    5px;
@padding-small-horizontal:  10px;

@padding-xs-vertical:       1px;
@padding-xs-horizontal:     5px;

@line-height-base:          1.42857143;
// 20/14
@line-height-computed:      floor(@font-size-base * @line-height-base);
@line-height-large:         1.33;
@line-height-small:         1.5;

@border-radius-base:        3px;
@border-radius-large:       4px;
@border-radius-small:       2px;
@border-color:              @brand-light-dark;

//** Global color for active items (e.g., navs or dropdowns).
@component-active-color:    #fff;
//** Global background color for active items (e.g., navs or dropdowns).
@component-active-bg:       @brand-primary-dark;

//** Width of the `border` for generating carets that indicator dropdowns.
@caret-width-base:          4px;
//** Carets increase slightly in size for larger components.
@caret-width-large:         5px;

//== Lists
@list-v-spacing:			5px;

//== Tables
//
//## Customizes the `.table` component with basic values, each used across all table variations.

@table-border-color: lighten(@border-color, 4.5%);
@table-striped-color: lighten(@brand-light, 5%);

//** Padding for `<th>`s and `<td>`s.
@table-cell-padding:            14px;
//** Padding for cells in `.table-condensed`.
@table-condensed-cell-padding:  5px;

//** Default background color used for all tables.
@table-bg:                      transparent;
//** Background color used for `.table-striped`.
@table-bg-accent:               @brand-lighter;
//** Background color used for `.table-hover`.
@table-bg-hover:                lighten(@brand-light, 2%);
@table-bg-active:               @table-bg-hover;


//== Buttons
//
//## For each of Bootstrap's buttons, define text, background and border color.

@btn-font-weight:                normal;

@btn-default-color:				@text-color;
@btn-default-bg:				darken(@brand-lighter, 1%);
@btn-default-border:			@dark-accent;
@btn-border-radius:				@border-radius-base;

@btn-primary-color:              #fff;
@btn-primary-bg:                 @brand-primary-dark;
@btn-primary-border:             @btn-primary-bg;

@btn-success-color:              #fff;
@btn-success-bg:                 @brand-success;
@btn-success-border:             @btn-success-bg;

@btn-info-color:                 #fff;
@btn-info-bg:                    @brand-info;
@btn-info-border:                @btn-info-bg;

@btn-warning-color:              #fff;
@btn-warning-bg:                 @brand-warning;
@btn-warning-border:             @btn-warning-bg;

@btn-danger-color:               #fff;
@btn-danger-bg:                  @brand-danger;
@btn-danger-border:              @btn-danger-bg;

@btn-default-dark-color:         @brand-light;
@btn-default-dark-bg:            lighten(@brand-dark, 5%);
@btn-default-dark-border:        @btn-default-dark-bg;

@btn-link-disabled-color:        @gray-light;


//== Forms
//
//##

//** `<input>` background color
@input-bg:                       #fff;
//** `<input disabled>` background color
@input-bg-disabled:              @gray-lighter;

//** Text color for `<input>`s
@input-color:                    @gray;
//** `<input>` border color
@input-border:                   darken(@border-color, 5%);
@input-border-focus:             @brand-primary;

// TODO: Rename `@input-border-radius` to `@input-border-radius-base` in v4
//** Default `.form-control` border radius
@input-border-radius:            @border-radius-base;
//** Large `.form-control` border radius
@input-border-radius-large:      @border-radius-large;
//** Small `.form-control` border radius
@input-border-radius-small:      @border-radius-small;

//** Placeholder text color
@input-color-placeholder:        #BBB;

//** Default `.form-control` height
@input-height-base:              (@line-height-computed + (@padding-base-vertical * 2) + 2);
//** Large `.form-control` height
@input-height-large:             (ceil(@font-size-large * @line-height-large) + (@padding-large-vertical * 2) + 2);
//** Small `.form-control` height
@input-height-small:             (floor(@font-size-small * @line-height-small) + (@padding-small-vertical * 2) + 2);

@legend-color:                   @gray-dark;
@legend-border-color:            @brand-light-dark;

//** Background color for textual input addons
@input-group-addon-bg:           @brand-light;
//** Border color for textual input addons
@input-group-addon-border-color: @input-border;

//** Disabled cursor for form controls and buttons.
@cursor-disabled:                not-allowed;


//== Dropdowns
//
//## Dropdown menu container and contents.

//** Background for the dropdown menu.
@dropdown-bg:                    #fff;
//** Dropdown menu `border-color`.
@dropdown-border:                rgba(0,0,0,.15);
//** Dropdown menu `border-color` **for IE8**.
@dropdown-fallback-border:       #ccc;
//** Divider color for between dropdown items.
@dropdown-divider-bg:            @brand-light-dark;

//** Dropdown link text color.
@dropdown-link-color:            @gray-dark;
//** Hover color for dropdown links.
@dropdown-link-hover-color:      darken(@gray-dark, 5%);
//** Hover background for dropdown links.
@dropdown-link-hover-bg:         @brand-light;

//** Active dropdown menu item text color.
@dropdown-link-active-color:     @component-active-color;
//** Active dropdown menu item background color.
@dropdown-link-active-bg:        @component-active-bg;

//** Disabled dropdown menu item background color.
@dropdown-link-disabled-color:   @gray-light;

//** Text color for headers within dropdown menus.
@dropdown-header-color:          @gray-light;

//** Deprecated `@dropdown-caret-color` as of v3.1.0
@dropdown-caret-color:           #000;

@dropdown-box-shadow: 			 0 2px 12px rgba(0, 0, 0, .09);


//-- Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.
//
// Note: These variables are not generated into the Customizer.

@zindex-ui-splitbar:       600;
@zindex-navbar:            600;
@zindex-dropdown:          650;
@zindex-popover:           700;
@zindex-tooltip:           750;
@zindex-sidebar:           800;
@zindex-navbar-fixed:      850;
@zindex-fullscreen:        1020;
@zindex-modal:             1050;


//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
//** Deprecated `@screen-xs` as of v3.0.1
@screen-xs:                  480px;
//** Deprecated `@screen-xs-min` as of v3.2.0
@screen-xs-min:              @screen-xs;
//** Deprecated `@screen-phone` as of v3.0.1
@screen-phone:               @screen-xs-min;

// Small screen / tablet
//** Deprecated `@screen-sm` as of v3.0.1
@screen-sm:                  768px;
@screen-sm-min:              @screen-sm;
//** Deprecated `@screen-tablet` as of v3.0.1
@screen-tablet:              @screen-sm-min;

// Medium screen / desktop
//** Deprecated `@screen-md` as of v3.0.1
@screen-md:                  992px;
@screen-md-min:              @screen-md;
//** Deprecated `@screen-desktop` as of v3.0.1
@screen-desktop:             @screen-md-min;

// Large screen / wide desktop
//** Deprecated `@screen-lg` as of v3.0.1
@screen-lg:                  1200px;
@screen-lg-min:              @screen-lg;
//** Deprecated `@screen-lg-desktop` as of v3.0.1
@screen-lg-desktop:          @screen-lg-min;

// So media queries don't overlap when required, provide a maximum
@screen-xs-max:              (@screen-sm-min - 1);
@screen-sm-max:              (@screen-md-min - 1);
@screen-md-max:              (@screen-lg-min - 1);


//== Grid system
//
//## Define your custom responsive grid.

//** Number of columns in the grid.
@grid-columns:              12;
//** Padding between columns. Gets divided in half for the left and right.
@grid-gutter-width:         30px;
// Navbar collapse
//** Point at which the navbar becomes uncollapsed.
@grid-float-breakpoint:     @screen-sm-min;
//** Point at which the navbar begins collapsing.
@grid-float-breakpoint-max: (@grid-float-breakpoint - 1);


//== Container sizes
//
//## Define the maximum width of `.container` for different screen sizes.

// Small screen / tablet
@container-tablet:             (720px + @grid-gutter-width);
//** For `@screen-sm-min` and up.
@container-sm:                 @container-tablet;

// Medium screen / desktop
@container-desktop:            (940px + @grid-gutter-width);
//** For `@screen-md-min` and up.
@container-md:                 @container-desktop;

// Large screen / wide desktop
@container-large-desktop:      (1140px + @grid-gutter-width);
//** For `@screen-lg-min` and up.
@container-lg:                 @container-large-desktop;


//== Navbar
//
//##

// Basics of a navbar
@navbar-height:                    40px;
@navbar-margin-bottom:             @line-height-computed;
@navbar-border-radius:             @border-radius-base;
@navbar-padding-horizontal:        floor((@grid-gutter-width / 2));
@navbar-padding-vertical:          ((@navbar-height - @line-height-computed) / 2);
@navbar-collapse-max-height:       340px;

@navbar-default-color:             @brand-light-dark;
@navbar-default-bg:                @brand-dark;
@navbar-default-border:            transparent;

// Navbar links
@navbar-default-link-color:                @brand-light;
@navbar-default-link-hover-color:          @brand-light-dark;
@navbar-default-link-hover-bg:             darken(@navbar-default-bg, 5%);
@navbar-default-link-active-color:         @brand-light;
@navbar-default-link-active-bg:            fade(darken(@navbar-default-bg, 10%), 50%);
@navbar-default-link-active-border:        @brand-primary;
@navbar-default-link-disabled-color:       fade(@navbar-default-link-color, 50%);
@navbar-default-link-disabled-bg:          transparent;

// Navbar brand label
@navbar-default-brand-color:               @navbar-default-link-color;
@navbar-default-brand-hover-color:         darken(@navbar-default-brand-color, 10%);
@navbar-default-brand-hover-bg:            transparent;

// Navbar toggle
@navbar-default-toggle-hover-bg:           @navbar-default-link-hover-bg;
@navbar-default-toggle-icon-bar-bg:        fade(@navbar-default-link-color, 35%);
@navbar-default-toggle-border-color:       transparent;


// Inverted navbar
// Reset inverted navbar basics
@navbar-inverse-color:                      lighten(@gray-light, 15%);
@navbar-inverse-bg:                         #222;
@navbar-inverse-border:                     darken(@navbar-inverse-bg, 10%);

// Inverted navbar links
@navbar-inverse-link-color:                 lighten(@gray-light, 15%);
@navbar-inverse-link-hover-color:           #fff;
@navbar-inverse-link-hover-bg:              transparent;
@navbar-inverse-link-active-color:          @navbar-inverse-link-hover-color;
@navbar-inverse-link-active-bg:             darken(@navbar-inverse-bg, 10%);
@navbar-inverse-link-disabled-color:        #444;
@navbar-inverse-link-disabled-bg:           transparent;

// Inverted navbar brand label
@navbar-inverse-brand-color:                @navbar-inverse-link-color;
@navbar-inverse-brand-hover-color:          #fff;
@navbar-inverse-brand-hover-bg:             transparent;

// Inverted navbar toggle
@navbar-inverse-toggle-hover-bg:            #333;
@navbar-inverse-toggle-icon-bar-bg:         #fff;
@navbar-inverse-toggle-border-color:        #333;


@subnavbar-height:              			35px;
@subnavbar-color:               			lighten(@brand-dark, 15%);
@subnavbar-bg:                  			@brand-light-dark;


//== Navs
//
//##

//=== Shared nav styles
@nav-bg:                                    lighten(@brand-light, 3%);
@nav-link-padding:                          10px 15px;
@nav-link-hover-bg:                         @navbar-default-link-hover-bg;

@nav-disabled-link-color:                   fade(@navbar-default-color, 50%);
@nav-disabled-link-hover-color:             fade(@navbar-default-color, 50%);

//== Tabs
@nav-tabs-border-color:                     @border-color;

@nav-tabs-link-hover-border-color:          @border-color;

@nav-tabs-active-link-hover-bg:             @body-bg;
@nav-tabs-active-link-hover-color:          @text-color;
@nav-tabs-active-link-hover-border-color:   @border-color;

@nav-tabs-justified-link-border-color:            @border-color;
@nav-tabs-justified-active-link-border-color:     @body-bg;

//== Pills
@nav-pills-border-radius:                   @border-radius-base;
@nav-pills-active-link-hover-bg:            @component-active-bg;
@nav-pills-active-link-hover-color:         @component-active-color;


//== Pagination
//
//##

@pagination-color:                     @link-color;
@pagination-bg:                        #fff;
@pagination-border:                    #ddd;

@pagination-hover-color:               @link-hover-color;
@pagination-hover-bg:                  @gray-lighter;
@pagination-hover-border:              #ddd;

@pagination-active-color:              #fff;
@pagination-active-bg:                 @brand-primary;
@pagination-active-border:             @brand-primary;

@pagination-disabled-color:            @gray-light;
@pagination-disabled-bg:               #fff;
@pagination-disabled-border:           #ddd;


//== Pager
//
//##

@pager-bg:                             @pagination-bg;
@pager-border:                         @pagination-border;
@pager-border-radius:                  15px;

@pager-hover-bg:                       @pagination-hover-bg;

@pager-active-bg:                      @pagination-active-bg;
@pager-active-color:                   @pagination-active-color;

@pager-disabled-color:                 @pagination-disabled-color;


//== Jumbotron
//
//##

@jumbotron-padding:              30px;
@jumbotron-color:                inherit;
@jumbotron-bg:                   @gray-lighter;
@jumbotron-heading-color:        inherit;
@jumbotron-font-size:            ceil((@font-size-base * 1.5));


//== Form states and alerts
//
//## Define colors for form feedback states and, by default, alerts.

@state-success-text:             #3c763d;
@state-success-bg:               #dff0d8;
@state-success-border:           darken(spin(@state-success-bg, -10), 5%);

@state-info-text:                #31708f;
@state-info-bg:                  #d9edf7;
@state-info-border:              darken(spin(@state-info-bg, -10), 7%);

@state-warning-text:             #8a6d3b;
@state-warning-bg:               #fcf8e3;
@state-warning-border:           darken(spin(@state-warning-bg, -10), 5%);

@state-danger-text:              #a94442;
@state-danger-bg:                #f2dede;
@state-danger-border:            darken(spin(@state-danger-bg, -10), 5%);


//== Tooltips
//
//##

//** Tooltip max width
@tooltip-max-width:           200px;
//** Tooltip text color
@tooltip-color:               #fff;
//** Tooltip background color
@tooltip-bg:                  #000;
@tooltip-opacity:             .9;

//** Tooltip arrow width
@tooltip-arrow-width:         5px;
//** Tooltip arrow color
@tooltip-arrow-color:         @tooltip-bg;


//== Popovers
//
//##

//** Popover body background color
@popover-bg:                          #fff;
//** Popover maximum width
@popover-max-width:                   276px;
//** Popover border color
@popover-border-color:                rgba(0,0,0,.2);
//** Popover fallback border color
@popover-fallback-border-color:       #ccc;

//** Popover title background color
@popover-title-bg:                    darken(@popover-bg, 3%);

//** Popover arrow width
@popover-arrow-width:                 10px;
//** Popover arrow color
@popover-arrow-color:                 @popover-bg;

//** Popover outer arrow width
@popover-arrow-outer-width:           (@popover-arrow-width + 1);
//** Popover outer arrow color
@popover-arrow-outer-color:           fadein(@popover-border-color, 5%);
//** Popover outer arrow fallback color
@popover-arrow-outer-fallback-color:  darken(@popover-fallback-border-color, 20%);


//== Labels
//
//##

//** Default label background color
@label-default-bg:            @gray-light;
//** Primary label background color
@label-primary-bg:            @brand-primary;
//** Success label background color
@label-success-bg:            @brand-success;
//** Info label background color
@label-info-bg:               @brand-info;
//** Warning label background color
@label-warning-bg:            @brand-warning;
//** Danger label background color
@label-danger-bg:             @brand-danger;

//** Default label text color
@label-color:                 #fff;
//** Default text color of a linked label
@label-link-hover-color:      #fff;


//== Modals
//
//##

//** Padding applied to the modal body
@modal-inner-padding:         15px;

//** Padding applied to the modal title
@modal-title-padding:         15px;
//** Modal title line-height
@modal-title-line-height:     @line-height-base;

//** Background color of modal content area
@modal-content-bg:                             #fff;
//** Modal content border color
@modal-content-border-color:                   rgba(0,0,0,.2);
//** Modal content border color **for IE8**
@modal-content-fallback-border-color:          #999;

//** Modal backdrop background color
@modal-backdrop-bg:           #000;
//** Modal backdrop opacity
@modal-backdrop-opacity:      .5;
//** Modal header border color
@modal-header-border-color:   #e5e5e5;
//** Modal footer border color
@modal-footer-border-color:   @modal-header-border-color;

@modal-lg:                    900px;
@modal-md:                    600px;
@modal-sm:                    300px;


//== Alerts
//
//## Define alert colors, border radius, and padding.

@alert-padding:               15px;
@alert-border-radius:         @border-radius-base;
@alert-link-font-weight:      bold;

@alert-success-bg:            @state-success-bg;
@alert-success-text:          @state-success-text;
@alert-success-border:        @state-success-border;

@alert-info-bg:               @state-info-bg;
@alert-info-text:             @state-info-text;
@alert-info-border:           @state-info-border;

@alert-warning-bg:            @state-warning-bg;
@alert-warning-text:          @state-warning-text;
@alert-warning-border:        @state-warning-border;

@alert-danger-bg:             @state-danger-bg;
@alert-danger-text:           @state-danger-text;
@alert-danger-border:         @state-danger-border;


//== Progress bars
//
//##

//** Background color of the whole progress component
@progress-bg:                 #f5f5f5;
//** Progress bar text color
@progress-bar-color:          #fff;
//** Variable for setting rounded corners on progress bar.
@progress-border-radius:      @border-radius-base;

//** Default progress bar color
@progress-bar-bg:             @brand-primary;
//** Success progress bar color
@progress-bar-success-bg:     @brand-success;
//** Warning progress bar color
@progress-bar-warning-bg:     @brand-warning;
//** Danger progress bar color
@progress-bar-danger-bg:      @brand-danger;
//** Info progress bar color
@progress-bar-info-bg:        @brand-info;


//== List group
//
//##

@list-group-item-border: lighten(@border-color, 3%);
@list-group-item-hover: lighten(@brand-light, 3%);
@list-group-item-focus: darken(@brand-light, 3%);
@list-group-select-color: #dbeef9;
@list-group-active-color: @brand-info;

//** Background color on `.list-group-item`
@list-group-bg:                 #fff;
//** `.list-group-item` border color
@list-group-border:             #ddd;
//** List group border radius
@list-group-border-radius:      @border-radius-base;

//** Background color of single list items on hover
@list-group-hover-bg:           #f5f5f5;
//** Text color of active list items
@list-group-active-color:       @component-active-color;
//** Background color of active list items
@list-group-active-bg:          @component-active-bg;
//** Border color of active list elements
@list-group-active-border:      @list-group-active-bg;
//** Text color for content within active list items
@list-group-active-text-color:  lighten(@list-group-active-bg, 40%);

//** Text color of disabled list items
@list-group-disabled-color:      @gray-light;
//** Background color of disabled list items
@list-group-disabled-bg:         @gray-lighter;
//** Text color for content within disabled list items
@list-group-disabled-text-color: @list-group-disabled-color;

@list-group-link-color:         #555;
@list-group-link-hover-color:   @list-group-link-color;
@list-group-link-heading-color: #333;


//== Panels
//
//##



@panel-bg:                    #fff;
@panel-border:                @border-color;
@panel-heading-border:        lighten(@border-color, 5%);
@panel-list-group-border:     lighten(@border-color, 5%);
@panel-body-padding:          15px;
@panel-heading-padding:       10px 15px;
@panel-footer-padding:        @panel-heading-padding;
@panel-border-radius:         @border-radius-base;
@panel-heading-bg: lighten(@brand-light, 3%);


//** Border color for elements within panels
@panel-inner-border:          #ddd;
@panel-footer-bg:             #fff;

@panel-default-text:          @gray-dark;
@panel-default-border:        #ddd;
@panel-default-heading-bg:    #f5f5f5;

@panel-primary-text:          #fff;
@panel-primary-border:        @brand-primary;
@panel-primary-heading-bg:    @brand-primary;

@panel-success-text:          @state-success-text;
@panel-success-border:        @state-success-border;
@panel-success-heading-bg:    @state-success-bg;

@panel-info-text:             @state-info-text;
@panel-info-border:           @state-info-border;
@panel-info-heading-bg:       @state-info-bg;

@panel-warning-text:          @state-warning-text;
@panel-warning-border:        @state-warning-border;
@panel-warning-heading-bg:    @state-warning-bg;

@panel-danger-text:           @state-danger-text;
@panel-danger-border:         @state-danger-border;
@panel-danger-heading-bg:     @state-danger-bg;


//== Thumbnails
//
//##

//** Padding around the thumbnail image
@thumbnail-padding:           4px;
//** Thumbnail background color
@thumbnail-bg:                @body-bg;
//** Thumbnail border color
@thumbnail-border:            #ddd;
//** Thumbnail border radius
@thumbnail-border-radius:     @border-radius-base;

//** Custom text color for thumbnail captions
@thumbnail-caption-color:     @text-color;
//** Padding around the thumbnail caption
@thumbnail-caption-padding:   9px;


//== Wells
//
//##

@well-bg:                     #f5f5f5;
@well-border:                 darken(@well-bg, 7%);


//== Badges
//
//##

@badge-color:                 #fff;
//** Linked badge text color on hover
@badge-link-hover-color:      #fff;
@badge-bg:                    darken(@brand-light, 10%);

//** Badge text color in active nav link
@badge-active-color:          @link-color;
//** Badge background color in active nav link
@badge-active-bg:             #fff;

@badge-font-weight:           bold;
@badge-line-height:           1;
@badge-border-radius:         10px;


//== Breadcrumbs
//
//##

@breadcrumb-padding-vertical:   8px;
@breadcrumb-padding-horizontal: 15px;
//** Breadcrumb background color
@breadcrumb-bg:                 #f5f5f5;
//** Breadcrumb text color
@breadcrumb-color:              #ccc;
//** Text color of current page in the breadcrumb
@breadcrumb-active-color:       @gray-light;
//** Textual separator for between breadcrumb elements
@breadcrumb-separator:          "/";


//== Carousel
//
//##

@carousel-text-shadow:                        0 1px 2px rgba(0,0,0,.6);

@carousel-control-color:                      #fff;
@carousel-control-width:                      15%;
@carousel-control-opacity:                    .5;
@carousel-control-font-size:                  20px;

@carousel-indicator-active-bg:                #fff;
@carousel-indicator-border-color:             #fff;

@carousel-caption-color:                      #fff;


//== Close
//
//##

@close-font-weight:           bold;
@close-color:                 #000;
@close-text-shadow:           0 1px 0 #fff;


//== Code
//
//##

@code-color:                  @brand-dark;
@code-bg:                     @brand-light;

@kbd-color:                   #fff;
@kbd-bg:                      #333;

@pre-color:                   @code-color;
@pre-bg:                      @code-bg;
@pre-border-color:            @border-color;
@pre-scrollable-max-height:   340px;


//== Type
//
//##

//** Horizontal offset for forms and lists.
@component-offset-horizontal: 180px;
//** Text muted color
@text-muted:                  @gray-light;
//** Abbreviations and acronyms border color
@abbr-border-color:           @gray-light;
//** Headings small color
@headings-small-color:        @gray-light;
//** Blockquote small color
@blockquote-small-color:      @gray-light;
//** Blockquote font size
@blockquote-font-size:        (@font-size-base * 1.25);
//** Blockquote border color
@blockquote-border-color:     @gray-lighter;
//** Page header border color
@page-header-border-color:    @gray-lighter;
//** Width of horizontal description list titles
@dl-horizontal-offset:        @component-offset-horizontal;
//** Horizontal line color.
@hr-border:                   @gray-lighter;


// Extra options for the theme

@arrow-width: 8px;
@arrow-color: #fff;
@arrow-outer-width: (@arrow-width + 1);
@arrow-outer-color: rgba(0, 0, 0, .1);


@switch-width: 35px;
@switch-height: 20px;
@switch-md-width: 40px;
@switch-md-height: 24px;
@switch-lg-width: 50px;
@switch-lg-height: 30px;


@sidebar-bg: @brand-light;
@sidebar-link-color: @text-color;
@sidebar-hover-bg: darken(@sidebar-bg, 7%);
@sidebar-active-bg: @subnavbar-bg;
@sidebar-active-color: @sidebar-link-color;
@sidebar-badge-bg: transparent;
@sidebar-badge-color: rgba(0, 0, 0, .35);
@sidebar-width: 260px;
@sidebar-right-margin: 0px;
@sidebar-folded-width: 60px;
@sidebar-offscreen-width: 100%;
@sidebar-nav-border: @off-white;
@sidebar-nav-divider: lighten(@off-white, 3%);
@sidebar-nav-link-padding: 8px 15px;

@app-header-height: 50px;
@app-header-md-height: 60px;

@scroll-bar-width: 17px;
@butterbar-height: 3px;
@butterbar-time: 0.75s;

@off-screen-width: 75%;

@default-padding: 20px;


@code-mirror-dark-bg: @gray-darker;
@code-mirror-light-bg: @white;
