.decorative-panel-graphic(@top: false, @bottom: false) {

	@bg-img: '/shared/images/lines.png';
	@bg-img2x: '/shared/images/lines@2x.png';
	@bg-height: 200px;

	& when (@top = true) and (@bottom = true) {
		background-image: url(@bg-img), url(@bg-img);
		background-repeat: repeat-x, repeat-x;
		background-position: 0 (-@bg-height / 2), 50% calc(~"100% + " (@bg-height / 2));
		@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
			background-image: url(@bg-img2x), url(@bg-img2x);
			background-size: auto @bg-height, auto @bg-height;
		}
	}

	& when (@top = false), (@bottom = false) {
		background-image: url(@bg-img);
		background-repeat: repeat-x;
		& when (@top = true) {
			background-position: 0 (-@bg-height / 2);
		}
		& when (@bottom = true) {
			background-position: 50% calc(~"100% + " (@bg-height / 2));
		}
		& when (@top = false) and (@bottom = false) {
			background-position: 50% 50%;
		}
		@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
			background-image: url(@bg-img2x);
			background-size: auto @bg-height;
		}
	}
}
