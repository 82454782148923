.sidebar {
	transition: all 0.2s ease-in-out;
	@media (max-width: @screen-xs-max) {
		font-size: 1.1em;
		width: @sidebar-offscreen-width;
		left: -@sidebar-offscreen-width;
	}
	background-color: @sidebar-bg;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	width: @sidebar-width;
	z-index: @zindex-sidebar;

	display: flex;
	flex-direction: column;

	> * {
		flex: 0 0 auto;
	}
	> .sidebar-content {
		flex: 1 1 auto;
		.overflow-auto();
		padding: 1rem .75rem;
	}
	> .sidebar-header {
		padding: 1rem .75rem 0;
	}
	> .sidebar-footer {
		padding: 0 .75rem 1rem;
	}
	@media(max-width: @screen-xs-max) {
		display: block;
		.overflow-auto();
	}

	.actions-group {
		background-color: @brand-light;
		border-radius: @border-radius-base;
		padding: 1.5rem 1rem;

		&.primary {
			padding: 0;
		}
	}
	.actions-group:first-child {
		margin-bottom: 1rem;
	}
	.actions-group:last-child {
		margin-top: 1rem;
	}
	.actions {
		& + .actions {
			margin-top: .5rem;
		}
		> button, > a, > .dropdown > button, > .dropdown > a {
			width: 100%;
			margin-top: 1rem;
			&:first-child {
				margin-top: 0;
			}
		}
		&.btn-group > a {
			.ng-animate-all(.2s);
			&.ng-hide-add,
			&.ng-hide-remove.ng-hide-remove-active {
				overflow: hidden;
				width: 100%;
			}
			&.ng-hide-remove,
			&.ng-hide-add.ng-hide-add-active {
				overflow: hidden;
				width: 1%;
			}
		}
		> .done {
			.icon-checkmark();
		}
	}
	.actions-group-filters {
		.actions + .actions {
			margin-top: 1.5rem;
		}
		&.expandable {
			position: relative;

			> label {
				position: relative;
				display: block;
				margin-bottom: 1rem;
				margin-left: -1.75rem;
				padding-left: calc(~"15px + 0.75rem");
			}

			> .clear {
				position: absolute;
				top: 1.5rem;
				right: .75rem;
				cursor: pointer;
				font-size: .8em;
				color: @text-muted;
				.icon-x-after();
				&:after {
					margin-left: .75rem;
				}
			}

			> .parented {
				padding-left: 1rem;
			}
		}
	}

	.sidebar-offscreen-menu {
		.navbar {
			margin: -1rem -.75rem 0;
		}
		.navbar-collapse {
			border-top: none;
			box-shadow: none;
		}
		.navbar-nav > .active > a {
			background: @brand-primary;
			color: @white;
			border-bottom: none;
		}
		@media (min-width: @screen-sm-min) {
			display: none;
		}
	}

	ul.nav-list {
		&:extend(.nav all);

		> li > a {
			padding: @sidebar-nav-link-padding;
		}

		.has-status-icon {
			a {
				position: relative;
				padding-left: 40px;
				&:before {
					font-size: 1.2rem;
					position: absolute;
					left: 14px;
					top: 50%;
					transform: translateY(-50%);
				}
			}
		}

		> li {
			.ng-repeat-changes();
			margin-left: -.75rem;
			margin-right: -.75rem;

			> a:not(:link) {
				&:hover, &:focus {
					background-color: transparent;
				}
			}

			&.parent {
				font-weight: bold;
			}
			&.parented {
				> a {
					padding-left: 40px;
				}
			}
			&.parented.has-status-icon {
				> a {
					padding-left: 48px;
					&:before {
						left: 22px;
					}
				}
			}

			> a {
				transition: all .2s ease-in-out;
				.no-focus-border();
				padding-left: ~"calc(15px + .75rem)";
				//border-radius: 0 @border-radius-small @border-radius-small 0;
				color: @sidebar-link-color;
				&:hover,
				&:focus {
					background-color: @sidebar-hover-bg;
				}

				.notes {
					font-size: .8em;
					color: @gray-light;
					.right {
						float: right;
					}
					.clearfix();
				}
			}
		}
		> li.open {
			> a, > a:hover, > a:focus {
				background-color: @sidebar-hover-bg;
			}
		}
		> li.active {
			> a {
				background-color: @sidebar-active-bg;
				color: @sidebar-active-color;

				&:before {
					//color: @sidebar-active-color;
				}

				.notes {
					color: darken(@sidebar-active-color, 6%);
				}
			}
			> a:hover, > a:focus {
				background-color: darken(@sidebar-active-bg, 3%);
			}
		}
	}

	.badge {
		font-size: inherit;
		font-weight: bold;
		float: right;
		color: @sidebar-badge-color;
		background-color: @sidebar-badge-bg;
	}

	.section .title {
		padding-left: 10px;
		padding-right: 10px;
		font-size: @font-size-xsmall;
		font-weight: bold;
	}
	.navigation-title {
		padding: 0 10px 20px;
		font-weight: bold;
		text-align: center;
	}

	.footer {
		background-color: @sidebar-bg;
		width: 100%;
	}
}

.sidebar-list() {
	//	border: 0;
	//	background: @body-bg;
	//	.actionsGroup {
	//		background-color: @sidebar-bg;
	//		border: 1px solid @sidebar-nav-border;
	//	}
	//	.sidebar-offscreen-menu {
	//		.divider {
	//			border-bottom: 1px solid darken(@navbar-default-bg, 8%);
	//			margin-top: .5rem;
	//			margin-bottom: .5rem;
	//		}
	//	}
	//
	//	ul.nav-list {
	//		border: 1px solid @sidebar-nav-border;
	//		border-radius: @border-radius-base;
	//
	//		> li {
	//			border-bottom: 1px solid @sidebar-nav-divider;
	//			margin-left: 0;
	//			background-color: darken(@body-bg, 1%);
	//
	//			&:last-of-type {
	//				border-bottom: 0;
	//			}
	//		}
	//	}
}

.app.has-sidebar .app-content {
	left: @sidebar-width + @sidebar-right-margin;
	@media (max-width: @screen-xs-max) {
		left: 0;
	}
}
