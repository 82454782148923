.landing-page-employers {
	nav .login-links {
		a.candidates-signup {
			display: none;
		}
		a.team-signup {
			display: inline-block;
		}

		@media (max-width: @screen-xs-min) {
			.team-hidden-xs {
				display: none;
			}
		}
	}
}

.landing.employers {

	section.hero {
		.media-background('/images/employers/hero/hero-bg');
	}

	section.features {

		@media (min-width: @screen-sm-min) {
			.feature-listing li {
				padding-top: 23rem;
			}
		}

		.attract {
			.landing .feature-listing-sprite(@index: 0);
		}
		.recruit {
			.landing .feature-listing-sprite(@index: 1);
		}
		.qualify {
			.landing .feature-listing-sprite(@index: 2);
		}
	}

	section.active-brands {
		.dark-bg();
		.decorative-panel-graphic();
		> div {
			padding: 12rem 0 0 0;
		}
	}

	section.assessments {

		.filtering {
			.landing .feature-listing-sprite(@index: 3);
		}
		.assessments {
			.landing .feature-listing-sprite(@index: 4);
		}
		.find {
			.landing .feature-listing-sprite(@index: 5);
		}

		.screenshots {
			.media-background('/images/employers/screenshots/screenshots', @ext: '.png');
			background-repeat: no-repeat;
			background-size: cover;
			background-position: 50% 0;
			position: relative;
			padding: 0;
			margin: 2rem 0 -@section-vertical-padding-xs;
			height: 250px;

			@media only screen and (min-width: @screen-sm-min) {
				margin: 3rem 0 -@section-vertical-padding-sm;
				height: 30rem;
			}
			@media only screen and (min-width: @screen-md-min) {
				height: 42rem;
			}

			@media only screen and (min-width: @screen-lg-min) and (min-height: 80rem) {
				height: 50rem;
			}
		}
	}

	section.profiles {
		.dark-bg();
		.media-background('/images/employers/profiles/profiles-bg');
		background-position: 50% 50%;
		background-size: cover;

		h3 {
			text-align: left;
			.landing .underlined(@centered: false);
			&:after {
				max-width: none;
				width: 3.6em;
			}
		}

		h4 {
			color: @white;
		}
	}

	section.recruiting {

		.coming-soon {
			color: @text-muted;
		}

		.recruiting-steps li {
			padding-top: 2rem;
			padding-left: 2rem;
			&:before {
				display: none;
			}
		}
	}

	section.pricing {
		.dark-bg();
		.decorative-panel-graphic(@top: true, @bottom: true);

		.pricing-table {
			&, > li, > li > ul, > li > ul > li {
				list-style: none;
				padding: 0;
				margin: 0;
			}
			> li {
				border: 1px solid rgba(255, 255, 255, 10%);
				margin-bottom: 3rem;
			}
			h4, > li > ul > li {
				padding: 2rem;
			}
			h4 {
				text-align: center;
				margin: 0;
				background-color: @brand-black;
				font-weight: 600;
				font-sizE: 1.8rem;
				text-transform: uppercase;
			}
			.price {
				display: flex;
				align-items: center;
				> * {
					flex: 0 0 auto;
				}
				.unit {
					font-weight: 300;
					font-size: 2.8rem;
				}
				.amount {
					font: @landing-heading-font;
					font-weight: 600;
					font-size: 5rem;
					margin: 0 .5rem;
				}
				.per, .rate {
					font-size: 1.6rem;
				}
				.per {
					margin-right: .2em;
				}
			}
			.note {
				font-size: 1.7rem;
				opacity: 0.5;
			}
			.enterprise .price {
				display: block;
				font-size: 2.6rem;
			}
			@media only screen and (max-width: @screen-sm-max) {
				> li > ul {
					position: relative;
					padding-left: 20rem;
				}

				> li > ul > li:nth-child(odd) {
					background-color: @brand-black;
				}

				> li > ul > li.cost {
					background-color: transparent;
					position: absolute;
					top: 0;
					left: 0;
					width: 20rem;
				}

				.enterprise > ul {
					padding-left: 0;
					> li.cost {
						text-align: center;
						position: relative;
						width: auto;
					}
					> li:not(.cost) {
						display: none;
					}
				}
			}
			@media only screen and (max-width: @screen-xs-max) {
				> li {
					text-align: center;
				}

				> li > ul {
					padding-left: 0;
				}

				> li > ul > li {
					padding: 1.5rem;
				}

				> li > ul > li:nth-child(odd) {
					background-color: transparent;
				}

				> li > ul > li:nth-child(even) {
					background-color: @brand-black;
				}

				> li > ul > li.cost {
					position: relative;
					top: auto;
					left: auto;
					width: auto;

					.price {
						justify-content: center;
					}
				}
			}

			@media only screen and (min-width: @screen-md-min) {
				font-size: 1.7rem;
				display: flex;
				justify-content: center;
				align-items: stretch;
				align-content: flex-start;
				h4 {
					padding: 3rem;
				}

				> li {
					flex: 0 1 auto;
				}

				> li:not(:first-child) {
					border-left: none;
				}

				> li > ul {
					> li {
						white-space: nowrap;
						text-align: center;
						padding: 3rem 3rem;
					}
					> li:nth-child(even) {
						background-color: @brand-black;
					}
					.cost {
						padding-top: .5rem;
						height: 16rem;
					}

					.price {
						justify-content: center;
					}
				}

				.enterprise > ul {
					.price {
						line-height: 7.15rem;
					}
				}
			}

			@media only screen and (min-width: @screen-lg-min) {
				font-size: 2rem;
			}
		}

		.pricing-footnotes {
			list-style-position: inside;
			text-align: center;
			padding: 0 2rem 2rem;
			font-size: 1.2rem;
			color: rgba(255, 255, 255, 35%);
		}
	}
}
