#intercom-container #intercom-launcher {
	top: 0;
	right: 0;
	width: @navbar-height;
	height: @navbar-height;
	.intercom-launcher-button {
		transition: all .25s ease-in-out;
		background-size: 16px 16px;
		background-position: 50% 50%;
		background-color: @brand-primary-dark;
		box-shadow: none;
		border: none;
		border-radius: 0 0 0 35%;
		width: @navbar-height;
		height: @navbar-height;
		&:hover, &:focus {
			background-color: @brand-primary;
		}

		.has-fixed-navbar:not(.modal-open) & {
			background-color: @navbar-default-bg;
			border-radius: 0;
			&:hover, &:focus {
				background-color: @navbar-default-link-active-bg;
			}
		}
	}

	// TODO: replace with an inline element for modals, and hide the default one
	.modal-open & {
		top: 8px;
		right: 8px;
		.intercom-launcher-button {
			border-radius: 50%;
		}
	}

	.fullscreen-active & {
		display: none;
	}
}
