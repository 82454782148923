.btn-default,
.btn-primary,
.btn-success,
.btn-info,
.btn-warning,
.btn-danger {
	&:extend(.btn all);
	text-transform: uppercase;

	&.disabled,
	&[disabled],
	fieldset[disabled] & {
		opacity: 1;
		background-color: rgba(0, 0, 0, 0.15);
		border-color: transparent;
		color: rgba(0, 0, 0, 0.25);
	}
}

.btn-default:hover {
	border-color: transparent;
}

.btn-sm, .btn {
	&:before {
		margin-right: .5rem;
	}
	&.add {
		.icon-plus();
	}
	&.edit {
		.icon-pencil();
	}
	&.phone, &.interview {
		.icon-phone();
	}
	&.reject {
		.icon-status-stop();
	}
	&.remove {
		.icon-minus();
	}
	&.publish {
		.icon-broadcast();
	}
	&.manage {
		.icon-cog();
	}
	&.finished {
		.icon-checkmark();
	}
	&.delete {
		.icon-warning();
	}
	&.email {
		.icon-email();
	}
	&.preview-link {
		.icon-link();
	}
	&.duplicate {
		.icon-duplicate();
	}
}

.btn-group {
	.btn, .btn-sm {
		text-align: center;
	}

	.btn-default:not(:last-child):not(:first-child) {
		border-left: 0;
		border-right: 0;
	}
}

.dropdown-menu {
	a {
		cursor: pointer;
		&:before {
			margin-right: .5rem;
		}
	}

	.dropdown-header {
		text-transform: uppercase;
		font-size: 13px;
		&:not(:first-of-type) {
			margin-top: 1rem;
		}
	}
}

.actions {
}

.actions-bar {
	margin-bottom: 1rem;

	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;

	> * {
		flex: 0 0 auto;
	}

	> a, > .dropdown, .disabled-btn-wrapper {
		display: inline-block;
		margin-right: .5rem;
		margin-top: .5rem;
	}

	a:before {
		margin-right: .5rem;
	}
}

.btn:focus, .btn:active:focus, .btn.active:focus,
.btn.focus, .btn:active.focus, .btn.active.focus {
	outline: none;
}

.disabled-btn-wrapper {
	display: inline-block;
}

.form-submit-buttons() {
	margin: 5rem auto;
	padding: 0 .5em;

	display: flex;
	justify-content: center;
	> * {
		max-width: 25em;
		flex: 1 1 50%;
		margin: 0 1rem;
		&:first-child {
			margin-left: 0;
		}
		&:last-child {
			margin-right: 0;
		}
	}
}
