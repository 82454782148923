.app-reset-page {
	.cleared-content {
		display: none;
	}
	.reset-button {
		margin: 3rem 0;
		text-align: center;
	}
	&.cleared {
		.not-cleared-content {
			display: none;
		}
		.cleared-content {
			display: block;
		}
	}
}
