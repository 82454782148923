.ng-animate-all(@time: .3s, @fn: ease-in-out) {
	&.ng-hide-add, &.ng-hide-remove {
		transition: all @time @fn;
	}
}
.ngif-animate-all(@time: .3s, @fn: ease-in-out) {
	&.ng-enter, &.ng-leave {
		transition: all @time @fn;
	}
}

.drop-in(@distance: 100%, @time: .3s, @fn: ease-in-out) {
	.ng-animate-all(@time, @fn);

	&.ng-hide-add,
	&.ng-hide-remove.ng-hide-remove-active {
		opacity: 1;
		transform: translateY(0);
	}
	&.ng-hide-remove,
	&.ng-hide-add.ng-hide-add-active {
		opacity: 0;
		transform: translateY(-@distance);
	}
}

.slide-in(@max-height: 100vh, @time: 0.3s, @fn: ease-in-out) {
	.ng-animate-all(@time, @fn);
	&.ng-hide-add,
	&.ng-hide-remove.ng-hide-remove-active {
		display: block;
		overflow: hidden;
		max-height: @max-height;
		opacity: 1;
	}
	&.ng-hide-remove,
	&.ng-hide-add.ng-hide-add-active {
		display: block;
		overflow: hidden;
		max-height: 0;
		margin-top: 0;
		margin-bottom: 0;
		opacity: 0;
	}
}

.fade-in(@time: 0.2s, @fn: ease-in-out) {
	.ng-animate-all(@time, @fn);
	&.ng-hide-add,
	&.ng-hide-remove.ng-hide-remove-active {
		opacity: 1;
	}
	&.ng-hide-remove,
	&.ng-hide-add.ng-hide-add-active {
		opacity: 0;
	}
}

.ngif-slide-in(@max-height: 100vh, @time: 0.3s, @fn: ease-in-out) {
	.ngif-animate-all(@time, @fn);
	&.ng-leave,
	&.ng-enter.ng-enter-active {
		display: block;
		overflow: hidden;
		max-height: @max-height;
		opacity: 1;
	}
	&.ng-enter,
	&.ng-leave.ng-leave-active {
		display: block;
		overflow: hidden;
		max-height: 0;
		margin-top: 0;
		margin-bottom: 0;
		opacity: 0;
	}
}

.ng-repeat-fade-in(@time: 0.2s, @fn: ease-in-out) {
	&.ng-enter, &.ng-leave, &.ng-move {
		transition: all @time @fn;
	}
	&.ng-leave,
	&.ng-move.ng-move-active,
	&.ng-enter.ng-enter-active {
		opacity: 1;
	}
	&.ng-enter,
	&.ng-move,
	&.ng-leave.ng-leave-active {
		opacity: 0;
	}
}

.ng-repeat-fade-in-enter(@time: 0.2s, @fn: ease-in-out) {
	&.ng-enter {
		transition: all @time @fn;
		opacity: 0;
	}
	&.ng-enter.ng-enter-active {
		opacity: 1;
	}
}

.ng-repeat-stretch-vertical(@max-height: 100vh, @time: 0.2s, @fn: ease-in-out) {
	&.ng-enter, &.ng-leave, &.ng-move {
		transition: all @time @fn;
		overflow: hidden;
	}
	&.ng-leave,
	&.ng-move.ng-move-active,
	&.ng-enter.ng-enter-active {
		opacity: 1;
		max-height: @max-height;
	}
	&.ng-enter,
	&.ng-move,
	&.ng-leave.ng-leave-active {
		opacity: 0;
		max-height: 0;
	}
}

.ng-repeat-stretch-horizontal(@max-width: 100vw, @time: 0.2s, @fn: ease-in-out) {
	&.ng-enter, &.ng-leave, &.ng-move {
		transition: all @time @fn;
		overflow: hidden;
	}
	&.ng-leave,
	&.ng-move.ng-move-active,
	&.ng-enter.ng-enter-active {
		opacity: 1;
		max-width: @max-width;
	}
	&.ng-enter,
	&.ng-move,
	&.ng-leave.ng-leave-active {
		opacity: 0;
		max-width: 0;
	}
}

.ng-repeat-stretch-all(@max-size: 100vw, @time: 0.2s, @fn: ease-in-out) {
	&.ng-enter, &.ng-leave, &.ng-move {
		transition: all @time @fn;
		overflow: hidden;
	}
	&.ng-leave,
	&.ng-move.ng-move-active,
	&.ng-enter.ng-enter-active {
		opacity: 1;
		max-width: @max-size;
		max-height: @max-size;
	}
	&.ng-enter,
	&.ng-move,
	&.ng-leave.ng-leave-active {
		opacity: 0;
		max-width: 0;
		max-height: 0;
	}
}

.ng-repeat-changes() {
	&.ng-enter:not(.no-highlight) {
		animation: 1.2s ng-repeat-changes-enter;
	}
	&.ng-leave:not(.no-highlight) {
		animation: .3s ng-repeat-changes-leave;
	}

	@keyframes ng-repeat-changes-enter {
		0% {
			opacity: 0;
			background: fade(@brand-highlight, 80%);
		}
		15% {
			opacity: 1;
			background: fade(@brand-highlight, 80%);
		}
		30% {
			background: fade(@brand-highlight, 40%);
		}
		45% {
			background: @brand-highlight;
		}
		60% {
			background: @brand-highlight;
		}
		100% {
			background: fade(@brand-highlight, 0);
		}
	}

	@keyframes ng-repeat-changes-leave {
		0% {
			background: fade(@brand-highlight, 0);
		}
		30% {
			background: fade(@brand-highlight, 50%);
			opacity: 1;
		}
		100% {
			opacity: 0;
		}
	}

}
