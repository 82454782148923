.has-code-challenge-navbar() {
	.navbar {
		height: 46px;
		border-radius: 0;
		background-color: @subnavbar-bg;

		// hack to fix paddings. This will all be reworked soon enough anyway.
		.container-fluid {
			padding-left: 0;
			margin-left: -10px;
			margin-top: 3px;
		}

		.navbar-btn {
			margin-top: 5px;
		}

		.btn {
			margin-right: 5px;
		}
		.reset {
			margin-right: 5px;
		}
		.language-label {
			margin-right: 1rem;
		}
		.language-menu {
			margin-top: .3rem;
			width: auto;
		}
	}

	.challenge-body {
		top: 46px;
	}
}

.has-code-challenge-info() {
	.info {
		.tab-scroller {
			background-color: @subnavbar-bg;
		}
		.tab-pane, .preview-pane {
			padding: 1em;
			position: absolute;
			top: 42px;
			bottom: 0;
			right: 0;
			left: 0;
			.overflow-auto();
			background-color: @nav-tabs-active-link-hover-bg;
			border-left: 1px solid @gray-lightest;
		}
	}
}

.has-code-challenge-results() {
	.results {
		margin-bottom: 0;
		overflow: hidden;

		.panel-heading {
			button {
				position: absolute;
				right: 5px;
				top: 5px;
			}
		}

		.panel-body {
			position: absolute;
			top: 46px;
			left: 0;
			right: 0;
			bottom: 0;
			.overflow-auto();
			background-color: darken(@body-bg, 3%);
			padding: @padding-base-horizontal @padding-large-horizontal @padding-base-horizontal*3;
		}

		.panel-progress {
			height: 5px;
			background-color: @gray;

			div {
				background-color: @brand-success-bright;
				height: 5px;
			}
		}

		.panel-progress.has-result {
			background-color: @brand-danger;
		}

		.banner {
			margin: -12px -16px 1rem;
			padding: 1.25rem;
			color: @gray-darker;
			background-color: @gray-lightest;
			min-height: 60px;
			.icon-status-complete();

			&:before {
				font-size: 3rem;
				line-height: 4rem;
				margin-right: 1rem;
				float: left;
				color: @gray;
			}

			&.state-completed {
				background-color: @brand-success-light;
				&:before {
					color: @brand-success;
				}
			}

			div {
				margin-left: 40px;
			}

			a {
				cursor: pointer;
			}
		}
	}
}

.has-code-challenge-code() {

	@notification-stripe-width: 5px;

	.CodeMirror {
		border-left: @notification-stripe-width solid @subnavbar-bg;
		transition: border-left-color .2s ease-in-out;
		position: absolute;
		bottom: 0;
		right: 0;
		left: 0;
		top: 0;
		height: auto;
	}

	.fail .CodeMirror {
		border-left-color: @brand-danger;
		animation-name: notification-stripe-pulse;
		animation-duration: 1s;
		animation-timing-function: linear;
	}
	.passed .CodeMirror {
		border-left-color: @brand-success;
	}
}

.has-code-tabs() {
	.ide-light& .code-tabs .nav-tabs > li.code-tab.active > a {
		background-color: @code-mirror-light-bg;
	}
	.ide-dark& .code-tabs .nav-tabs > li.code-tab.active > a {
		color: @white;
		background-color: @code-mirror-dark-bg;
	}

	.code-tabs {
		.tab-scroller {
			background-color: @subnavbar-bg;
		}
		.tab-pane {
			padding: 1em;
			position: absolute;
			top: 42px;
			bottom: 0;
			right: 0;
			left: 0;
			.overflow-auto();
			background-color: @nav-tabs-active-link-hover-bg;
			border-left: 1px solid @gray-lightest;
		}
	}
}

@keyframes notification-stripe-pulse {
	0% {
		border-left-color: transparent;
	}
	20% {
		border-left-color: @brand-danger;
	}
	40% {
		border-left-color: transparent;
	}
	60% {
		border-left-color: @brand-danger;
	}
	80% {
		border-left-color: transparent;
	}
	100% {
		border-left-color: @brand-danger;
	}
}
