@media (max-width: @screen-xs-max) {
	select,
	textarea,
	input[type="text"],
	input[type="password"],
	input[type="datetime"],
	input[type="datetime-local"],
	input[type="date"],
	input[type="month"],
	input[type="time"],
	input[type="week"],
	input[type="number"],
	input[type="email"],
	input[type="url"],
	input[type="search"],
	input[type="tel"],
	input[type="color"] {
		font-size: 18px !important;
	}
}

// Prevent MSIE from adding an "X" to input boxes
input[type]::-ms-clear {
    display: none;
}

.control-label {
	.help {
		transition: all .25s ease-in-out;
		opacity: 0;
		margin-left: .5em;
		.icon-status-unknown();
		color: @text-color;
		text-decoration: none;
		&:hover {
			color: @link-hover-color;
		}
	}
	&:hover .help, .has-focus & .help {
		opacity: 1;
	}
}

.help-block {
	transition: .25s ease all;

	&.msg-shouldhide, &.ng-inactive, &.focused-help-block {
		margin-top: 0;
		margin-bottom: 0;
		overflow: hidden;
		line-height: 0;
	}

	&.msg-shouldshow.ng-active, .has-focus &.focused-help-block {
		margin-top: 5px;
		margin-bottom: 10px;
		line-height: @line-height-base;
	}
}

label {
	color: @headings-color;
}

label[disabled] {
	color: fade(@text-color, 50%);
}

// enables [enter] to submit a form without a visible submit button.
.hidden-submit {
	display: block;
	position: absolute;
	top: -99999px;
	left: -99999px;
}

form .form-buttons {
	margin-top: 2.5rem;
	padding-top: 1.5rem;
	border-top: 1px solid @border-color;
	> * {
		min-width: 8em;
	}
}

.form-group:not(.has-error) .form-control.ng-invalid-required {
	border-color: lighten(@brand-primary-dark, 30%);
}

// override error colors when a form field is actually value
// (handles multiple-field situations)
.has-error .form-control.ng-valid {
	border-color: @input-border;
}

form h3 {
	color: @legend-color;
	padding: .75em 0 .25em;
	border-bottom: 1px solid @legend-border-color;
}

.form-control-select {
	position: relative;
	&:extend(.form-control all);
	padding: 0;
	.icon-chevron-down-after();

	.has-focus & {
		border-color: @input-border-focus;
	}

	&:after {
		// DO NOT CHANGE TO (.input-group-addon all)
		// This breaks Firefox, as it sees :after:first-child as an invalid selector!
		&:extend(.input-group-addon);
		pointer-events: none;
		font-size: .7em;
		position: absolute;
		top: -1px;
		bottom: -1px;
		right: -1px;
		width: 3em;
		padding: 1.3em 0;
		border-bottom-left-radius: 0;
		border-top-left-radius: 0;

		.has-focus & {
			border-color: @input-border-focus;
		}
		.has-success & {
			color: @state-success-text;
			border-color: @state-success-text;
			background-color: @state-success-bg;
		}
		.has-warning & {
			color: @state-warning-text;
			border-color: @state-warning-text;
			background-color: @state-warning-bg;
		}
		.has-error & {
			color: @state-danger-text;
			border-color: @state-danger-text;
			background-color: @state-danger-bg;
		}
	}
	select {
		&:extend(.form-control all);
		//width: ~"calc(100% + 16px)";
		margin: 0;
		border: 0;
		height: 32px;
		background: inherit;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		padding-right: 35px; // make enough room to show the content + custom chevron
		//noinspection CssInvalidPseudoSelector
		&::-ms-expand {
			display: none;
		}
	}

	&.no-control {
		display: inline-block;
		width: auto;
		select {
			padding-top: 0;
			padding-bottom: 0;
			border: none;
		}
	}
}

.input-range {
	display: flex;
	> * {
		flex: 1 1 auto;
	}
	> .sep {
		&:extend(.form-control-static all);
		flex: 0 0 auto;
		padding-left: 1em;
		padding-right: 1em;
	}
}
