.item-list {
	.no-focus-border();

	.as-sortable-placeholder {
		border: 2px dashed @border-color;

		& + .list-item {
			border-top-color: transparent;
		}
	}

	.list-item {
		padding: @list-v-spacing 0;
		clear: both;
		position: relative;
		display: flex;
		.clearfix();

		border-top: 1px solid @border-color;
		&:first-child {
			border-top: none;
		}

		.list-item-content {
			padding: 1rem;
			flex-grow: 1;

			> .title {
				font-weight: bold;
				font-size: 1.75rem;
				margin-bottom: 1rem;
			}

			> .labels {
				margin-top: .5rem;

				.label {
					display: inline-block;
					font-weight: normal;
					margin-right: .2rem;
				}
			}

			> .line-item {
				.line-label {
					font-weight: bold;
					letter-spacing: 1px;
					text-transform: uppercase;
					font-size: 1.2rem;
				}

				.line-value {
				}
			}

		}

		.list-item-actions {
			flex-shrink: 0;
			align-content: center;
			align-self: center;
			position: relative;
			float: right;
			min-width: 80px;
			@media (min-width: @screen-md-min) {
				min-width: 170px;
			}

			> .actions {
				width: 100%;
				text-align: center;
			}
		}

		.list-item-sort {
			float: left;
			min-width: 60px;
			border-right: 1px solid darken(@brand-light, 7%);
			position: relative;

			&:hover {
				background-color: lighten(@brand-light, 5%);
			}

			> .sort {
				.center();
				.icon-drag-sort();
				font-size: 1.25em;
			}
		}

		&.edit-mode {
		}

		&.sortable {
			user-select: none;
		}

	}
}

