.dark-bg {
	color: @brand-light-dark;
	background-color: @brand-dark;
	h1, h2, h3, h4, h5, h6 {
		color: @brand-light-dark;
	}
	a {
		color: @brand-light;

		&:hover,
		&:focus {
			color: #FFF;
		}
	}
	.btn-default {
	  .button-variant(@btn-default-dark-color; @btn-default-dark-bg; @btn-default-dark-border);
	}

	.strive-logo {
		color: @brand-light;
	}

	.badge {
		background-color: @gray;
		color: @gray-lighter;
	}
}
