.landing-page {
	background-color: @brand-light;

	.footer {
		.dark-bg();
		background-color: @brand-black;
		padding: 1rem;
		@media (min-width: @screen-sm-min) {
			padding: 3rem 2rem 2rem;
		}

		color: @brand-accent;
		a {
			color: @brand-accent;
			&:hover {
				color: @brand-primary;
			}
		}
	}
}

.landing {

	font-size: 1.6rem;
	font-weight: 300;
	background-color: @brand-light;

	h1, h3, h4 {
		font-family: @landing-heading-font;
	}
	h1 {
		font-weight: 600;
		letter-spacing: .062em;
	}
	h3 {
		font-weight: 600;
		letter-spacing: .125em;

		&.sub {
			font-weight: 300;
			letter-spacing: .062em;
		}
	}
	h4 {
		font-weight: 300;
		letter-spacing: .03em;
	}

	section {
		> div {
			transition: all .2s ease-in-out;
			padding: @section-vertical-padding-xs 1rem;
			margin: 0 auto;
			max-width: @section-max-width;

			@media (min-width: @screen-sm-min) {
				padding: @section-vertical-padding-sm 1rem;
				> p {
					text-align: center;
					max-width: 30em;
					margin-left: auto;
					margin-right: auto;
				}
			}
		}

		h3 {
			transition: all .2s ease-in-out;
			font-size: 2.4rem;
			text-transform: uppercase;
			text-align: center;
			margin: @section-vertical-padding-xs 0 4rem;
			&:first-child {
				margin-top: 0;
			}
			@media (min-width: @screen-sm-min) {
				font-size: 3rem;
				margin-top: @section-vertical-padding-sm;
			}
		}

		h4 {
			font-size: 2.0rem;
			margin: 4rem 0 1.8rem;
			@media (min-width: @screen-sm-min) {
				font-size: 2.6rem;
				margin: 6rem 0 2.4rem;
			}
		}
	}

	aside {
		@media (min-width: @screen-sm-min) {
			max-width: 60%;
		}
		@media (min-width: @screen-md-min) {
			max-width: 40%;
		}
	}

	.feature-listing {
		margin: 0;
		padding: 0;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;

		li {
			position: relative;
			flex: 1 1 100%;
			margin: 0;
			padding: 2rem 1rem 2rem calc(~"2rem + 7rem");
			min-height: 9rem;
			list-style: none;
			&:before {
				content: '';
				display: block;
				background: transparent url('/images/illustrations/illustrations-sm.png') no-repeat 50% 0;
				@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
					background-image: url('/images/illustrations/illustrations-sm@2x.png');
					background-size: 70px auto;
				}
				position: absolute;
				left: 1rem;
				top: 50%;
				width: 7rem;
				height: 9rem;
				transform: translateY(-50%);
			}

			h4 {
				font-size: 2rem;
				margin-top: .5rem;
				.landing .underlined();
			}
			p {
				color: @text-muted;
			}
		}

		@media only screen and (min-width: @screen-sm-min) {
			padding-bottom: 2rem;
			li {
				padding: 20rem 2.5rem 0;
				flex: 0 1 auto;
				width: 33%; // fix for IE10-11: https://github.com/philipwalton/flexbugs#7-flex-basis-doesnt-account-for-box-sizingborder-box
				max-width: @section-max-width / 3;
				text-align: center;
				&:before {
					background-image: url('/images/illustrations/illustrations-md.png');
					top: 0;
					left: 50%;
					transform: translateX(-50%);
					width: 16rem;
					height: 20rem;
				}

				h4 {
					white-space: nowrap;
				}
			}
		}
		@media only screen and (min-width: @screen-md-min) {
			li {
				padding-left: 4rem;
				padding-right: 4rem;
			}
		}
		@media only screen and (min-width: @screen-sm-min) and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-width: @screen-sm-min) and (min-device-pixel-ratio: 1.5) {
			li:before {
				background-image: url('/images/illustrations/illustrations-md@2x.png');
				background-size: 160px auto;
			}
		}
	}

	.feature-listing.numbered {
		counter-reset: feature-listing;
		li:before {
			counter-increment: feature-listing;
			content: counter(feature-listing);
			font-weight: 300;
			text-align: center;
			background-image: none;
			background-color: @brand-primary-dark;
			color: @brand-light;
			font-size: 2.8rem;
			width: 4rem;
			height: 4rem;
			border-radius: 50%;
			top: 2.7rem;
			left: 2.5rem;
			transform: none;
		}

		@media (min-width: @screen-sm-min) {
			li {
				padding-top: 18rem;

				&:before {
					top: 5rem;
					left: 50%;
					transform: translateX(-50%);
					font-size: 5.6rem;
					width: 8rem;
					height: 8rem;
				}
			}
		}
	}

	section.hero {
		position: relative;
		z-index: @zindex-navbar-fixed + 1;

		.dark-bg();
		background-position: 50% 50%;
		background-size: cover;

		text-align: center;

		nav {
			transition: all .2s ease-in-out;
			background: transparent;
			position: absolute;
			top: 2rem;
			width: 100%;
		}

		> div {
			transition: all .2s ease-in-out;
			padding: 16rem .5rem;
		}

		h1 {
			transition: all .2s ease-in-out;
			font-size: 3.4rem;
			color: @white;
			text-transform: uppercase;
		}

		h2 {
			transition: all .2s ease-in-out;
			font-weight: 300;
			font-size: 1.7rem;
			line-height: 2.8rem;
			color: @white;
			margin: 2rem auto 1rem;
			max-width: 540px;
		}

		@media (min-width: @screen-sm-min) {

			nav {
				top: 4rem;
			}

			h1 {
				font-size: 5.4rem;
			}

			h2 {
				margin: 3rem auto 2rem;
				font-size: 2.6rem;
				line-height: 4rem;
				max-width: 640px;
			}
		}

		.cta {
			padding: 1rem 0 0;
			a {
				color: @white;
				display: inline-block;
				text-align: center;
				min-width: 12em;
				font-size: 1.6rem;
				letter-spacing: 1px;
				padding: .5rem;
				margin: 1rem;
				border: 2px solid @brand-primary;
				border-radius: 20px;
				text-transform: uppercase;
				transition: all .2s ease-in-out;
				&:hover {
					text-decoration: none;
					color: @white;
					background-color: fade(@brand-black, 50%);
					box-shadow: inset 0 0 7px fade(@brand-primary, 60%), 0 0 7px fade(@brand-primary, 60%);
				}
			}
			.secondary {
				border-color: darken(@brand-light-dark, 10%);
				color: darken(@brand-light-dark, 10%);
				&:hover {
					border-color: @white;
					box-shadow: inset 0 0 7px fade(@white, 40%), 0 0 7px fade(@white, 40%);
				}
			}

			@media (min-width: @screen-sm-min) {
				padding: 4rem 0;

				a {
					padding: 1rem;
					min-width: 14em;
					font-size: 2rem;
					letter-spacing: 2px;
					border-radius: 26px;
				}
			}
		}
	}


	//---------------------------------------------------------------
	// MIXINS
	//---------------------------------------------------------------

	.underlined(@centered: true) {
		@space-to-line: 2rem;

		&:after {
			display: block;
			content: '';
			height: 1px;
			background-color: @brand-primary;
			margin: @space-to-line auto @space-to-line 0;
			width: 50%;
			max-width: 12rem;
		}
		& when (@centered = true) {
			@media (min-width: @screen-sm-min) {
				&:after {
					margin: @space-to-line auto;
				}
			}
		}

	}

	.feature-listing-sprite(@index) {
		&:before {
			background-position: 50% -90px * @index;
		}
		@media (min-width: @screen-sm-min) {
			&:before {
				background-position: 50% -200px * @index;
			}
		}
	}
}

@import 'landing/candidates';
@import 'landing/employers';
