.oauth-buttons {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 0;
	padding: 0;
	text-align: center;

	> li {
		flex: 1 0 100%;
		list-style: none;
		margin: 0;
		padding: .5em 0;
	}
	> li > a {
		width: 100%;
	}
}

@vendor-strive-primary: @brand-primary-dark;
@vendor-strive-button: @brand-primary-dark;
@vendor-strive-secondary: #FFF;

@vendor-email-primary: @btn-default-bg;
@vendor-email-button: @btn-default-bg;
@vendor-email-secondary: @btn-default-color;

@vendor-linkedin-primary: #0976b4;
@vendor-linkedin-button: #508db4;
@vendor-linkedin-secondary: #FFF;

@vendor-google-primary: #4285f4;
@vendor-google-button: #769ef4;
@vendor-google-secondary: #FFF;

@vendor-github-primary: #4183c4;
@vendor-github-button: #7693c4;
@vendor-github-secondary: #FFF;

@vendor-angellist-primary: #FFF;
@vendor-angellist-button: #FFF;
@vendor-angellist-secondary: #000;

.btn-vendor {
	&:extend(.btn-default all);
	position: relative;
	text-transform: none;
	text-align: left;
	padding-left: 3.25em;
	text-overflow: ellipsis;
	&:before {
		position: absolute;
		left: .333em;
		top: 50%;
		transform: translateY(-50%);
		display: block;
		font-size: 1.5em;
		padding-right: .333em;
		border-right: 1px solid transparent;
	}

	&.connected, &.connecting {
		padding-right: 3em;
		&:after {
			position: absolute;
			right: .333em;
			top: 50%;
			transform: translateY(-50%);
			display: block;
			font-size: 1.5em;
			padding-left: .333em;
		}
	}

	&.connected {
		.icon-checkmark-after();
	}
	&.connecting {
		.icon-dot-after();
		&:after {
			animation: connecting 1s infinite;
			animation-timing-function: ease-in-out;
		}
	}
}

@keyframes connecting {
	0%, 100% {
		opacity: .2;
	}
	50% {
		opacity: .8;
	}
}

.make-btn-vendor(@vendor, @ico: "vendor-@{vendor}") {
	@bgc: "vendor-@{vendor}-button";
	@fgc: "vendor-@{vendor}-secondary";
	.btn-vendor.@{vendor} {
		.icon-common-icons(@ico);
		color: @@fgc;
		background-color: @@bgc;
		border-color: @@bgc;
		&:hover {
			color: @@fgc;
			background-color: @@bgc;
			border-color: @@bgc;
		}
		&:before, &:after {
			border-color: fade(@@fgc, 33%);
		}
	}
}

.make-btn-vendor(strive, strive-logo-icon);
.make-btn-vendor(email, email);
.make-btn-vendor(linkedin);
.make-btn-vendor(google);
.make-btn-vendor(github);
.make-btn-vendor(angellist);
