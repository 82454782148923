.loading-panel {
	.fade-in(.35s);
	background-color: @brand-light;
	color: @brand-light-dark;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: @zindex-fullscreen;
	div {
		position: absolute;
		width: 100%;
		top: 50%;
		transform: translateY(-50%);
		text-align: center;
		//opacity: .25;
		font-size: 3rem;
		text-shadow: 0 -1px 1px darken(@brand-light-dark, 15%), 0 1px 1px #FFF;

		.strive-logo:before, .strive-logo:after {
			color: @brand-light-dark;
		}
	}
}
