article.simple {
	margin: 0 auto;
	padding: (@navbar-height + @navbar-margin-bottom) 20px 20px;
	max-width: 60em;

	section {
		margin-bottom: 2em;
	}

	&.notification {
		text-align: center;
	}
}
