@font-face {
	font-family: "common-icons";
	src: url('/shared/icons/common-icons.eot');
	src: url('/shared/icons/common-icons.eot?#iefix') format('eot'),
		url('/shared/icons/common-icons.woff') format('woff'),
		url('/shared/icons/common-icons.ttf') format('truetype'),
		url('/shared/icons/common-icons.svg#common-icons') format('svg');
}

.icon-common-icons-base-pseudo {
	font-family: "common-icons";
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	text-decoration: none;
	text-transform: none;
}

.icon-common-icons-char(@filename) {
	@archive: "\E001";
	@article: "\E002";
	@articles: "\E003";
	@blank: "\E004";
	@broadcast: "\E005";
	@calendar: "\E006";
	@checkmark-empty: "\E007";
	@checkmark: "\E008";
	@chevron-down: "\E009";
	@chevron-left: "\E00A";
	@chevron-right: "\E00B";
	@chevron-up: "\E00C";
	@clock: "\E00D";
	@coffeepot: "\E00E";
	@cog: "\E00F";
	@cogs: "\E010";
	@contract-all: "\E011";
	@contract: "\E012";
	@decline: "\E013";
	@description: "\E014";
	@dot: "\E015";
	@drag-sort: "\E016";
	@duplicate: "\E017";
	@email: "\E018";
	@expand-all: "\E019";
	@expand: "\E01A";
	@eye: "\E01B";
	@flipflops: "\E01C";
	@globe: "\E01D";
	@home: "\E01E";
	@inbox: "\E01F";
	@job-add: "\E020";
	@job: "\E021";
	@lang-clojure: "\E022";
	@lang-coffeescript: "\E023";
	@lang-csharp: "\E024";
	@lang-haskell: "\E025";
	@lang-java: "\E026";
	@lang-javascript: "\E027";
	@lang-python: "\E028";
	@lang-ruby: "\E029";
	@link: "\E02A";
	@magnifying-glass: "\E02B";
	@map-marker: "\E02C";
	@minus: "\E02D";
	@new-window: "\E02E";
	@office: "\E02F";
	@pencil: "\E030";
	@phone: "\E031";
	@pickaxe: "\E032";
	@plus: "\E033";
	@resume: "\E034";
	@rubiks-add: "\E035";
	@rubiks: "\E036";
	@stack: "\E037";
	@status-access: "\E038";
	@status-all: "\E039";
	@status-complete: "\E03A";
	@status-done: "\E03B";
	@status-fail: "\E03C";
	@status-info: "\E03D";
	@status-new: "\E03E";
	@status-partial: "\E03F";
	@status-secure: "\E040";
	@status-stop: "\E041";
	@status-unknown: "\E042";
	@stopwatch-add: "\E043";
	@stopwatch: "\E044";
	@strive-logo-icon: "\E045";
	@strive-logo-text: "\E046";
	@strive-logo: "\E047";
	@tag: "\E048";
	@tags: "\E049";
	@tasks: "\E04A";
	@team: "\E04B";
	@thumbs-down: "\E04C";
	@thumbs-up: "\E04D";
	@transport-first: "\E04E";
	@transport-forward: "\E04F";
	@transport-last: "\E050";
	@transport-next: "\E051";
	@transport-pause: "\E052";
	@transport-play: "\E053";
	@transport-previous: "\E054";
	@transport-rewind: "\E055";
	@transport-stop: "\E056";
	@trashcan: "\E057";
	@unbroadcast: "\E058";
	@undo: "\E059";
	@upload-cloud: "\E05A";
	@upload: "\E05B";
	@user-admin: "\E05C";
	@user: "\E05D";
	@users: "\E05E";
	@vendor-angellist: "\E05F";
	@vendor-facebook: "\E060";
	@vendor-github: "\E061";
	@vendor-google: "\E062";
	@vendor-linkedin: "\E063";
	@vendor-stackoverflow: "\E064";
	@vendor-strive: "\E065";
	@vendor-twitter: "\E066";
	@warning: "\E067";
	@x: "\E068";
	
	content: @@filename;
}

.icon-common-icons(@filename, @insert: before) {
	@pseudo-selector: ~":@{insert}";

	&@{pseudo-selector} {
		&:extend(.icon-common-icons-base-pseudo);
		.icon-common-icons-char(@filename);
	}
}

.icon-archive() {
	.icon-common-icons(archive);
}
.icon-archive-after() {
 	.icon-common-icons(archive, after);
 }
.icon-article() {
	.icon-common-icons(article);
}
.icon-article-after() {
 	.icon-common-icons(article, after);
 }
.icon-articles() {
	.icon-common-icons(articles);
}
.icon-articles-after() {
 	.icon-common-icons(articles, after);
 }
.icon-blank() {
	.icon-common-icons(blank);
}
.icon-blank-after() {
 	.icon-common-icons(blank, after);
 }
.icon-broadcast() {
	.icon-common-icons(broadcast);
}
.icon-broadcast-after() {
 	.icon-common-icons(broadcast, after);
 }
.icon-calendar() {
	.icon-common-icons(calendar);
}
.icon-calendar-after() {
 	.icon-common-icons(calendar, after);
 }
.icon-checkmark-empty() {
	.icon-common-icons(checkmark-empty);
}
.icon-checkmark-empty-after() {
 	.icon-common-icons(checkmark-empty, after);
 }
.icon-checkmark() {
	.icon-common-icons(checkmark);
}
.icon-checkmark-after() {
 	.icon-common-icons(checkmark, after);
 }
.icon-chevron-down() {
	.icon-common-icons(chevron-down);
}
.icon-chevron-down-after() {
 	.icon-common-icons(chevron-down, after);
 }
.icon-chevron-left() {
	.icon-common-icons(chevron-left);
}
.icon-chevron-left-after() {
 	.icon-common-icons(chevron-left, after);
 }
.icon-chevron-right() {
	.icon-common-icons(chevron-right);
}
.icon-chevron-right-after() {
 	.icon-common-icons(chevron-right, after);
 }
.icon-chevron-up() {
	.icon-common-icons(chevron-up);
}
.icon-chevron-up-after() {
 	.icon-common-icons(chevron-up, after);
 }
.icon-clock() {
	.icon-common-icons(clock);
}
.icon-clock-after() {
 	.icon-common-icons(clock, after);
 }
.icon-coffeepot() {
	.icon-common-icons(coffeepot);
}
.icon-coffeepot-after() {
 	.icon-common-icons(coffeepot, after);
 }
.icon-cog() {
	.icon-common-icons(cog);
}
.icon-cog-after() {
 	.icon-common-icons(cog, after);
 }
.icon-cogs() {
	.icon-common-icons(cogs);
}
.icon-cogs-after() {
 	.icon-common-icons(cogs, after);
 }
.icon-contract-all() {
	.icon-common-icons(contract-all);
}
.icon-contract-all-after() {
 	.icon-common-icons(contract-all, after);
 }
.icon-contract() {
	.icon-common-icons(contract);
}
.icon-contract-after() {
 	.icon-common-icons(contract, after);
 }
.icon-decline() {
	.icon-common-icons(decline);
}
.icon-decline-after() {
 	.icon-common-icons(decline, after);
 }
.icon-description() {
	.icon-common-icons(description);
}
.icon-description-after() {
 	.icon-common-icons(description, after);
 }
.icon-dot() {
	.icon-common-icons(dot);
}
.icon-dot-after() {
 	.icon-common-icons(dot, after);
 }
.icon-drag-sort() {
	.icon-common-icons(drag-sort);
}
.icon-drag-sort-after() {
 	.icon-common-icons(drag-sort, after);
 }
.icon-duplicate() {
	.icon-common-icons(duplicate);
}
.icon-duplicate-after() {
 	.icon-common-icons(duplicate, after);
 }
.icon-email() {
	.icon-common-icons(email);
}
.icon-email-after() {
 	.icon-common-icons(email, after);
 }
.icon-expand-all() {
	.icon-common-icons(expand-all);
}
.icon-expand-all-after() {
 	.icon-common-icons(expand-all, after);
 }
.icon-expand() {
	.icon-common-icons(expand);
}
.icon-expand-after() {
 	.icon-common-icons(expand, after);
 }
.icon-eye() {
	.icon-common-icons(eye);
}
.icon-eye-after() {
 	.icon-common-icons(eye, after);
 }
.icon-flipflops() {
	.icon-common-icons(flipflops);
}
.icon-flipflops-after() {
 	.icon-common-icons(flipflops, after);
 }
.icon-globe() {
	.icon-common-icons(globe);
}
.icon-globe-after() {
 	.icon-common-icons(globe, after);
 }
.icon-home() {
	.icon-common-icons(home);
}
.icon-home-after() {
 	.icon-common-icons(home, after);
 }
.icon-inbox() {
	.icon-common-icons(inbox);
}
.icon-inbox-after() {
 	.icon-common-icons(inbox, after);
 }
.icon-job-add() {
	.icon-common-icons(job-add);
}
.icon-job-add-after() {
 	.icon-common-icons(job-add, after);
 }
.icon-job() {
	.icon-common-icons(job);
}
.icon-job-after() {
 	.icon-common-icons(job, after);
 }
.icon-lang-clojure() {
	.icon-common-icons(lang-clojure);
}
.icon-lang-clojure-after() {
 	.icon-common-icons(lang-clojure, after);
 }
.icon-lang-coffeescript() {
	.icon-common-icons(lang-coffeescript);
}
.icon-lang-coffeescript-after() {
 	.icon-common-icons(lang-coffeescript, after);
 }
.icon-lang-csharp() {
	.icon-common-icons(lang-csharp);
}
.icon-lang-csharp-after() {
 	.icon-common-icons(lang-csharp, after);
 }
.icon-lang-haskell() {
	.icon-common-icons(lang-haskell);
}
.icon-lang-haskell-after() {
 	.icon-common-icons(lang-haskell, after);
 }
.icon-lang-java() {
	.icon-common-icons(lang-java);
}
.icon-lang-java-after() {
 	.icon-common-icons(lang-java, after);
 }
.icon-lang-javascript() {
	.icon-common-icons(lang-javascript);
}
.icon-lang-javascript-after() {
 	.icon-common-icons(lang-javascript, after);
 }
.icon-lang-python() {
	.icon-common-icons(lang-python);
}
.icon-lang-python-after() {
 	.icon-common-icons(lang-python, after);
 }
.icon-lang-ruby() {
	.icon-common-icons(lang-ruby);
}
.icon-lang-ruby-after() {
 	.icon-common-icons(lang-ruby, after);
 }
.icon-link() {
	.icon-common-icons(link);
}
.icon-link-after() {
 	.icon-common-icons(link, after);
 }
.icon-magnifying-glass() {
	.icon-common-icons(magnifying-glass);
}
.icon-magnifying-glass-after() {
 	.icon-common-icons(magnifying-glass, after);
 }
.icon-map-marker() {
	.icon-common-icons(map-marker);
}
.icon-map-marker-after() {
 	.icon-common-icons(map-marker, after);
 }
.icon-minus() {
	.icon-common-icons(minus);
}
.icon-minus-after() {
 	.icon-common-icons(minus, after);
 }
.icon-new-window() {
	.icon-common-icons(new-window);
}
.icon-new-window-after() {
 	.icon-common-icons(new-window, after);
 }
.icon-office() {
	.icon-common-icons(office);
}
.icon-office-after() {
 	.icon-common-icons(office, after);
 }
.icon-pencil() {
	.icon-common-icons(pencil);
}
.icon-pencil-after() {
 	.icon-common-icons(pencil, after);
 }
.icon-phone() {
	.icon-common-icons(phone);
}
.icon-phone-after() {
 	.icon-common-icons(phone, after);
 }
.icon-pickaxe() {
	.icon-common-icons(pickaxe);
}
.icon-pickaxe-after() {
 	.icon-common-icons(pickaxe, after);
 }
.icon-plus() {
	.icon-common-icons(plus);
}
.icon-plus-after() {
 	.icon-common-icons(plus, after);
 }
.icon-resume() {
	.icon-common-icons(resume);
}
.icon-resume-after() {
 	.icon-common-icons(resume, after);
 }
.icon-rubiks-add() {
	.icon-common-icons(rubiks-add);
}
.icon-rubiks-add-after() {
 	.icon-common-icons(rubiks-add, after);
 }
.icon-rubiks() {
	.icon-common-icons(rubiks);
}
.icon-rubiks-after() {
 	.icon-common-icons(rubiks, after);
 }
.icon-stack() {
	.icon-common-icons(stack);
}
.icon-stack-after() {
 	.icon-common-icons(stack, after);
 }
.icon-status-access() {
	.icon-common-icons(status-access);
}
.icon-status-access-after() {
 	.icon-common-icons(status-access, after);
 }
.icon-status-all() {
	.icon-common-icons(status-all);
}
.icon-status-all-after() {
 	.icon-common-icons(status-all, after);
 }
.icon-status-complete() {
	.icon-common-icons(status-complete);
}
.icon-status-complete-after() {
 	.icon-common-icons(status-complete, after);
 }
.icon-status-done() {
	.icon-common-icons(status-done);
}
.icon-status-done-after() {
 	.icon-common-icons(status-done, after);
 }
.icon-status-fail() {
	.icon-common-icons(status-fail);
}
.icon-status-fail-after() {
 	.icon-common-icons(status-fail, after);
 }
.icon-status-info() {
	.icon-common-icons(status-info);
}
.icon-status-info-after() {
 	.icon-common-icons(status-info, after);
 }
.icon-status-new() {
	.icon-common-icons(status-new);
}
.icon-status-new-after() {
 	.icon-common-icons(status-new, after);
 }
.icon-status-partial() {
	.icon-common-icons(status-partial);
}
.icon-status-partial-after() {
 	.icon-common-icons(status-partial, after);
 }
.icon-status-secure() {
	.icon-common-icons(status-secure);
}
.icon-status-secure-after() {
 	.icon-common-icons(status-secure, after);
 }
.icon-status-stop() {
	.icon-common-icons(status-stop);
}
.icon-status-stop-after() {
 	.icon-common-icons(status-stop, after);
 }
.icon-status-unknown() {
	.icon-common-icons(status-unknown);
}
.icon-status-unknown-after() {
 	.icon-common-icons(status-unknown, after);
 }
.icon-stopwatch-add() {
	.icon-common-icons(stopwatch-add);
}
.icon-stopwatch-add-after() {
 	.icon-common-icons(stopwatch-add, after);
 }
.icon-stopwatch() {
	.icon-common-icons(stopwatch);
}
.icon-stopwatch-after() {
 	.icon-common-icons(stopwatch, after);
 }
.icon-strive-logo-icon() {
	.icon-common-icons(strive-logo-icon);
}
.icon-strive-logo-icon-after() {
 	.icon-common-icons(strive-logo-icon, after);
 }
.icon-strive-logo-text() {
	.icon-common-icons(strive-logo-text);
}
.icon-strive-logo-text-after() {
 	.icon-common-icons(strive-logo-text, after);
 }
.icon-strive-logo() {
	.icon-common-icons(strive-logo);
}
.icon-strive-logo-after() {
 	.icon-common-icons(strive-logo, after);
 }
.icon-tag() {
	.icon-common-icons(tag);
}
.icon-tag-after() {
 	.icon-common-icons(tag, after);
 }
.icon-tags() {
	.icon-common-icons(tags);
}
.icon-tags-after() {
 	.icon-common-icons(tags, after);
 }
.icon-tasks() {
	.icon-common-icons(tasks);
}
.icon-tasks-after() {
 	.icon-common-icons(tasks, after);
 }
.icon-team() {
	.icon-common-icons(team);
}
.icon-team-after() {
 	.icon-common-icons(team, after);
 }
.icon-thumbs-down() {
	.icon-common-icons(thumbs-down);
}
.icon-thumbs-down-after() {
 	.icon-common-icons(thumbs-down, after);
 }
.icon-thumbs-up() {
	.icon-common-icons(thumbs-up);
}
.icon-thumbs-up-after() {
 	.icon-common-icons(thumbs-up, after);
 }
.icon-transport-first() {
	.icon-common-icons(transport-first);
}
.icon-transport-first-after() {
 	.icon-common-icons(transport-first, after);
 }
.icon-transport-forward() {
	.icon-common-icons(transport-forward);
}
.icon-transport-forward-after() {
 	.icon-common-icons(transport-forward, after);
 }
.icon-transport-last() {
	.icon-common-icons(transport-last);
}
.icon-transport-last-after() {
 	.icon-common-icons(transport-last, after);
 }
.icon-transport-next() {
	.icon-common-icons(transport-next);
}
.icon-transport-next-after() {
 	.icon-common-icons(transport-next, after);
 }
.icon-transport-pause() {
	.icon-common-icons(transport-pause);
}
.icon-transport-pause-after() {
 	.icon-common-icons(transport-pause, after);
 }
.icon-transport-play() {
	.icon-common-icons(transport-play);
}
.icon-transport-play-after() {
 	.icon-common-icons(transport-play, after);
 }
.icon-transport-previous() {
	.icon-common-icons(transport-previous);
}
.icon-transport-previous-after() {
 	.icon-common-icons(transport-previous, after);
 }
.icon-transport-rewind() {
	.icon-common-icons(transport-rewind);
}
.icon-transport-rewind-after() {
 	.icon-common-icons(transport-rewind, after);
 }
.icon-transport-stop() {
	.icon-common-icons(transport-stop);
}
.icon-transport-stop-after() {
 	.icon-common-icons(transport-stop, after);
 }
.icon-trashcan() {
	.icon-common-icons(trashcan);
}
.icon-trashcan-after() {
 	.icon-common-icons(trashcan, after);
 }
.icon-unbroadcast() {
	.icon-common-icons(unbroadcast);
}
.icon-unbroadcast-after() {
 	.icon-common-icons(unbroadcast, after);
 }
.icon-undo() {
	.icon-common-icons(undo);
}
.icon-undo-after() {
 	.icon-common-icons(undo, after);
 }
.icon-upload-cloud() {
	.icon-common-icons(upload-cloud);
}
.icon-upload-cloud-after() {
 	.icon-common-icons(upload-cloud, after);
 }
.icon-upload() {
	.icon-common-icons(upload);
}
.icon-upload-after() {
 	.icon-common-icons(upload, after);
 }
.icon-user-admin() {
	.icon-common-icons(user-admin);
}
.icon-user-admin-after() {
 	.icon-common-icons(user-admin, after);
 }
.icon-user() {
	.icon-common-icons(user);
}
.icon-user-after() {
 	.icon-common-icons(user, after);
 }
.icon-users() {
	.icon-common-icons(users);
}
.icon-users-after() {
 	.icon-common-icons(users, after);
 }
.icon-vendor-angellist() {
	.icon-common-icons(vendor-angellist);
}
.icon-vendor-angellist-after() {
 	.icon-common-icons(vendor-angellist, after);
 }
.icon-vendor-facebook() {
	.icon-common-icons(vendor-facebook);
}
.icon-vendor-facebook-after() {
 	.icon-common-icons(vendor-facebook, after);
 }
.icon-vendor-github() {
	.icon-common-icons(vendor-github);
}
.icon-vendor-github-after() {
 	.icon-common-icons(vendor-github, after);
 }
.icon-vendor-google() {
	.icon-common-icons(vendor-google);
}
.icon-vendor-google-after() {
 	.icon-common-icons(vendor-google, after);
 }
.icon-vendor-linkedin() {
	.icon-common-icons(vendor-linkedin);
}
.icon-vendor-linkedin-after() {
 	.icon-common-icons(vendor-linkedin, after);
 }
.icon-vendor-stackoverflow() {
	.icon-common-icons(vendor-stackoverflow);
}
.icon-vendor-stackoverflow-after() {
 	.icon-common-icons(vendor-stackoverflow, after);
 }
.icon-vendor-strive() {
	.icon-common-icons(vendor-strive);
}
.icon-vendor-strive-after() {
 	.icon-common-icons(vendor-strive, after);
 }
.icon-vendor-twitter() {
	.icon-common-icons(vendor-twitter);
}
.icon-vendor-twitter-after() {
 	.icon-common-icons(vendor-twitter, after);
 }
.icon-warning() {
	.icon-common-icons(warning);
}
.icon-warning-after() {
 	.icon-common-icons(warning, after);
 }
.icon-x() {
	.icon-common-icons(x);
}
.icon-x-after() {
 	.icon-common-icons(x, after);
 }

