.landing.candidates {

	section.hero {
		.media-background('/images/candidates/hero/hero-bg');
	}

	section.features {

		@media (min-width: @screen-sm-min) {
			.feature-listing li {
				padding-top: 23rem;
			}
		}

		.tailored {
			.landing .feature-listing-sprite(@index: 1);
		}
		.privacy {
			.landing .feature-listing-sprite(@index: 6);
		}
		.search {
			.landing .feature-listing-sprite(@index: 5);
		}
	}


	section.commitment {
		.dark-bg();
		.decorative-panel-graphic(@top: true, @bottom: true);
		padding: 2rem 0;
	}
}
