.modal-footer {
	a, button, [type=button], [type=submit], [type=reset] {
		.btn-sm();
		min-width: 8em;
		margin-left: 1em;
		&:first-child {
			margin-left: 0;
		}
	}
	.or {
		position: relative;
		text-transform: uppercase;
		display: inline-block;
		font-size: 10px;
		padding: 0 .5em;
		color: @gray-light;
		margin: 0 1.7em;

		&:before, &:after {
			content: '';
			position: absolute;
			left: -1.5em;
			right: 100%;
			top: 50%;
			height: 0;
			border-bottom: 1px solid @gray-lighter;
		}
		&:after {
			left: 100%;
			right: -1.5em;
		}
		+ * {
			margin-left: 0;
		}
	}
}

// FIX FOR MODAL BACKDROPS
.modal-backdrop {
	bottom: 0;
}

.modal-content {
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.modal-fs {
	.modal-dialog {
		width: 100%;
		height: 100%;
		padding: 20px;
		margin: 0;
	}
	// some dialogs wrap everything in a form
	.modal-content, .modal-content > form {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
		margin: 0;
	}
	.modal-header {
		flex-grow: 0;
		flex-shrink: 0;
	}
	.modal-body {
		.overflow-auto();
		flex-grow: 1;
	}
	.modal-footer {
		flex-grow: 0;
		flex-shrink: 0;
	}
}

.inline-modal {
	min-height: 100vh;
	padding: 10px 0 30px;
	display: flex;
	justify-content: center;
	align-content: center;
	align-items: center;
	.modal-content {
		flex: 0 0 auto;
		color: @text-color;
		margin: 0;

		width: ~"calc(100% - 1em)";
		max-width: 35em;
	}

	.modal-content {
		background-color: @brand-light;

		.checkbox {
			margin-left: 3px;
		}
	}

	.modal-header {
		text-align: center;
		font-size: 1.5em;
		.strive-logo {
			font-size: 1.2em;
		}
		border-bottom-color: @gray-lighter;
	}

	.modal-footer {
		border-top-color: @gray-lighter;
		text-align: center;
		a, button, [type=button], [type=submit], [type=reset] {
			min-width: 10em;
		}
	}
}

.inline-modal-footer {
	&:extend(.legal-links all);

	position: fixed;
	bottom: 0;
	width: 100%;
	height: 2.5rem;
	z-index: @zindex-navbar-fixed;
}
